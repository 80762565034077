import {Type} from '@angular/core';

import {BaseFilter} from './base/base.filter';

export class AuctionFilter extends BaseFilter {

    public id: string;

    public constructor() {
        super();
    }

    protected getType(): Type<BaseFilter> {
        return AuctionFilter;
    }
}
