import {Type} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {BaseService} from './base.service';

import {BaseModel} from '@model/base/base.model';
import {BaseFilter} from '@filter/base/base.filter';
import {ReflectionUtils} from '@util/ReflectionUtils';
import {environment} from '@environments/environment';

export class SubTypeInfo {
    public name: string;

    public service: CrudService<any, any>;

    public constructor(
        name: string,
        service: CrudService<any, any>,
    ) {
        this.name = name;
        this.service = service;
    }
}

export class EnumInfo {
    public name: string;

    public enumObj: any;

    public constructor(
        name: string,
        enumObj: any,
    ) {
        this.name = name;
        this.enumObj = enumObj;
    }
}

export abstract class CrudService<E extends BaseModel, F extends BaseFilter> extends BaseService<E> {

    protected abstract entityPath: string;

    protected abstract filterClass: Type<F>;

    protected abstract entityClass: Type<E>;

    public constructor(
        protected httpClient: HttpClient,
    ) {
        super();
    }

    public search(filter: F): Observable<E[]> {
        return this.httpClient.post<E[]>(environment.apiUrl + `${this.entityPath}/search`, filter).pipe(
            map((data: E[]) => data.map(element => this.mapEntity(element))),
            catchError(error => this.handleExceptions(error)),
        );
    }

    public searchOne(filter: F): Observable<E> {
        return this.httpClient.post<E>(environment.apiUrl + `${this.entityPath}/search`, filter).pipe(
            map((data: E) => this.mapEntity(data)),
            catchError(error => this.handleExceptions(error)),
        );
    }

    public list(): Observable<E[]> {
        return this.httpClient.get<E[]>(environment.apiUrl + `${this.entityPath}`).pipe(
            map((data: E[]) => data.map(element => this.mapEntity(element))),
            catchError(error => this.handleExceptions(error)),
        );
    }

    public update(entity: E): Observable<E>{
        return this.httpClient.put<E>(environment.apiUrl + this.entityPath, entity).pipe(
            map((data: E) => this.mapEntity(data)),
            catchError(error => this.handleExceptions(error)),
        );
    }

    public count(filter: F): Observable<number> {
        return this.httpClient.post<number>(environment.apiUrl + `${this.entityPath}/count`, filter).pipe(
            map ((data: number) => data),
            catchError(error => this.handleExceptions(error)),
        );
    }

    public save(entity: E): Observable<E> {
        return this.httpClient.post<E>(environment.apiUrl + this.entityPath, entity).pipe(
            map((data: E) => this.mapEntity(data)),
            catchError(error => this.handleExceptions(error)),
        );
    }

    public saveList(entities: E[]): Observable<E[]> {
        return this.httpClient.post<E[]>(environment.apiUrl + `${this.entityPath}/list`, entities).pipe(
            map((data: E[]) => data.map(e => this.mapEntity(e))),
            catchError(error => this.handleExceptions(error)),
        );
    }

    public get(entityId: string): Observable<E> {
        return this.httpClient.get<E>(environment.apiUrl + `${this.entityPath}/${entityId}`).pipe(
            map((data: E) => this.mapEntity(data)),
            catchError(error => this.handleExceptions(error)),
        );
    }

    public delete(entityId: string): Observable<void> {
        return this.httpClient.delete<void>(environment.apiUrl + `${this.entityPath}/${entityId}`).pipe(
            map ((data: void) => data),
            catchError(error => this.handleExceptions(error)),
        );
    }

    public buildFilterParameters(): F {
        return new this.filterClass();
    }

    protected abstract getSubTypes(): SubTypeInfo[];

    protected abstract getEnums(): EnumInfo[];

    protected mapEntity(data: E): E {
        if (data == null) {
            return null;
        }

        const entity = new this.entityClass();

        Object.assign(entity, data);
        // adjust dates into proper type
        ReflectionUtils.getAllProperties(entity)
            .filter(value => value.endsWith('Date'))
            .filter(value => data[value] != null) // we only want non null ones
            .forEach(value => entity[value] = new Date(data[value] * 1000));  // have to multiply by 1000 because the value comes in seconds, we need millis

        this.getSubTypes().forEach(t => {
            if (Array.isArray(entity[t.name])) {
                entity[t.name] = data[t.name].map(v => t.service.mapEntity(v));
            } else {
                entity[t.name] = t.service.mapEntity(data[t.name]);
            }
        });

        this.getEnums().forEach(t => {
            if (Array.isArray(entity[t.name])) {
                entity[t.name] = data[t.name].map(v => {
                    try {
                        return t.enumObj.valueByName(v);
                    } catch (e) {
                        return null;
                    }
                });
            } else {
                try {
                    entity[t.name] = t.enumObj.valueByName(data[t.name]);
                } catch (e) {
                    return null;
                }
            }
        });

        return entity;
    }
}
