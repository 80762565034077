import {BaseModel} from './base/base.model';
import {Deserializable} from './deserializable.model';

export class GeoCountryModel extends BaseModel implements Deserializable {
    public codeIso2: string;
    public description: string;
    public codeIso3: string;

    public constructor() {
        super();
    }

    public deserialize(input: any){
        Object.assign(this, input);
        return this;
    }
}
