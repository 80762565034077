import {BaseModel} from '@model/base/base.model';
import {Deserializable} from './deserializable.model';

export class GeoPostalCodeModel extends BaseModel implements Deserializable {

    public postalCode: string;
    public geoPlaceId: string;
    public geoPlaceDesc: string;
    public geoCountyId: string;
    public geoCountyDesc: string;

   public deserialize(input: any): this {
        return undefined;
    }
}
