import {Enum} from './base/base.enum';

export class RoleEnum extends Enum {
    protected static LABEL_KEY_BASE: string = 'ENUMS.ROLE';

    public static readonly ROOT = new RoleEnum('ROOT');
    public static readonly ADMIN = new RoleEnum('ADMIN');
    public static readonly OPERATIONAL = new RoleEnum('OPERATIONAL');
    public static readonly TECHNICIAN = new RoleEnum('TECHNICIAN');
    public static readonly FINAL_USER = new RoleEnum('FINAL_USER');
}
