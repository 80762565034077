import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {NavigationEnd, Router} from '@angular/router';

@Injectable()
export class NavigationService {

    private history: string[] = [];

    public constructor(
        private route: Router,
        private location: Location,
    ) {
        this.route.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.history.push(event.urlAfterRedirects);
            }
        });
    }

    public back() {
        if (this.history.length > 0) {
            this.location.back();
        } else {
            this.route.navigateByUrl('/');
        }
    }
}
