<ng-container [formGroup]="formGroup">
    <div class="form-group {{extraFormGroupClass}}" [ngClass]="{ 'is-invalid': !readOnly && showErrors && inputCombobox.invalid }">

        <label *ngIf="label" for="{{name}}" [ngClass]="{ 'label-is-invalid': !readOnly && showErrors && inputCombobox.invalid }">
            {{label}}
            <ng-container *ngIf="required">(*)</ng-container>
        </label>

        <ng-container *ngIf="optionInfoTooltip && !optionInfoTemplate">
            <i class="fas fa-info-circle" ngbPopover="{{optionInfoTooltip}}"></i>
        </ng-container>

        <ng-container *ngIf="optionInfoTemplate && !optionInfoTooltip">
            <i class="fas fa-info-circle" [ngbPopover]="optionInfoTemplate"></i>
        </ng-container>

        <ng-select *ngIf="!readOnly"
            id="{{name}}"
            formControlName="inputCombobox"
            [ngClass]="{ 'is-invalid': showErrors && inputCombobox.invalid }"

            [items]="_currentItems"
            [multiple]="multiple"
            [clearOnBackspace]="!multiple"
            [maxSelectedItems]="maxSelectedItems"

            bindLabel="{{bindLabel}}"

            loadingText="{{loadingText}}"
            notFoundText="{{notFoundText}}"
            placeholder="{{placeholder}}"
            appendTo="{{appendToBody ? 'body' : null}}"

            [searchable]="!bindLabelIsTranslateKey && searchable"
            [clearable]="clearable"

            [loading]="loading"
            [virtualScroll]="true"

            (search)="searchDebounce($event)"
            (clear)="onClear($event)"
            (open)="onOpen($event)"
            (close)="onClose($event)"
            (scrollToEnd)="onScrollToEnd()"
            (change)="onChange($event)">

            <ng-template *ngIf="!multiple" ng-label-tmp ng-option-tmp let-item="item">
                <ng-template *ngTemplateOutlet="labelOptionDisplay || defaultOptionAndLabel; context: {'item': item, 'bindLabel': bindLabel, 'isTranslateKey': bindLabelIsTranslateKey}">
                </ng-template>
            </ng-template>

            <ng-container *ngIf="multiple">
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                    <ng-template *ngTemplateOutlet="defaultMultiLabel; context: {'item': item, 'bindLabel': bindLabel, 'isTranslateKey': bindLabelIsTranslateKey, 'clear': clear}">
                    </ng-template>
                </ng-template>

                <ng-template ng-option-tmp let-item="item">
                    <ng-template *ngTemplateOutlet="labelOptionDisplay || defaultOptionAndLabel; context: {'item': item, 'bindLabel': bindLabel, 'isTranslateKey': bindLabelIsTranslateKey}">
                    </ng-template>
                </ng-template>
            </ng-container>
        </ng-select>

        <ng-container *ngIf="readOnly">
            <ng-container *ngIf="!multiple" >
                <ng-template *ngTemplateOutlet="readOnlyDisplay || defaultReadOnlyDisplay; context: {'value': _valueObj, 'bindLabel': bindLabel, 'isTranslateKey': bindLabelIsTranslateKey}">
                </ng-template>
            </ng-container>

            <ng-container *ngIf="multiple">
                <ng-template *ngTemplateOutlet="defaultReadOnlyMultiDisplay; context: {'valueArr': _valueObj, 'bindLabel': bindLabel, 'isTranslateKey': bindLabelIsTranslateKey}">
                </ng-template>
            </ng-container>
        </ng-container>

        <div *ngIf="!readOnly && showErrors && inputCombobox.invalid" class="invalid-feedback">
            <div *ngIf="inputCombobox.errors.required">
                {{'ERRORS.REQUIRED_FIELD' | translate}}
            </div>
        </div>
    </div>
</ng-container>

<ng-template #defaultOptionAndLabel let-item="item" let-bindLabel="bindLabel" let-isTranslateKey="isTranslateKey">
    <span title="{{item ? (isTranslateKey ? (item[bindLabel] | translate) : (bindLabel ? item[bindLabel] : item)) : item}}" >{{item ? (isTranslateKey ? (item[bindLabel] | translate) : (bindLabel ? item[bindLabel] : item)) : item}}</span>
</ng-template>

<ng-template #defaultMultiLabel let-item="item" let-bindLabel="bindLabel" let-isTranslateKey="isTranslateKey" let-clear="clear">
    <ng-container *ngIf="labelOptionDisplay; else defaultMulti">
        <span class="ng-value-label">
            <ng-template *ngTemplateOutlet="labelOptionDisplay; context: {'item': item, 'bindLabel': bindLabel, 'isTranslateKey': bindLabelIsTranslateKey}"></ng-template>
        </span>
    </ng-container>

    <ng-template #defaultMulti>
        <span title="{{item ? (isTranslateKey ? (item[bindLabel] | translate) : (bindLabel ? item[bindLabel] : item)) : item}}" class="ng-value-label">{{item ? (isTranslateKey ? (item[bindLabel] | translate) : (bindLabel ? item[bindLabel] : item)) : item}}</span>
    </ng-template>

    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
</ng-template>

<ng-template #defaultReadOnlyDisplay let-value="value" let-bindLabel="bindLabel" let-isTranslateKey="isTranslateKey">
    <input type="text" class="form-control" [readOnly]="true" value="{{value ? (isTranslateKey ? (value[bindLabel] | translate) : (bindLabel ? value[bindLabel] : value)) : value}}">
</ng-template>

<ng-template #defaultReadOnlyMultiDisplay let-valueArr="valueArr" let-bindLabel="bindLabel" let-isTranslateKey="isTranslateKey">
    <div class="row">
        <div class="col-12">
            <span *ngFor="let value of valueArr" title="{{value ? (isTranslateKey ? (value[bindLabel] | translate) : (bindLabel ? value[bindLabel] : value)) : value}}" class="ng-value-label-readonly">{{value ? (isTranslateKey ? (value[bindLabel] | translate) : (bindLabel ? value[bindLabel] : value)) : value}}</span>
        </div>
    </div>
</ng-template>
