import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CrudService, EnumInfo, SubTypeInfo} from './crud.service';
import {AddressModel} from '@model/address.model';
import {AddressFilter} from '@filter/address.filter';

@Injectable()
export class AddressService extends CrudService<AddressModel, AddressFilter> {
    protected entityClass = AddressModel;

    protected filterClass = AddressFilter;

    protected entityPath = '/address';

    public constructor(protected httpClient: HttpClient) {
        super(httpClient);
    }

    protected getEnums(): EnumInfo[] {
        return [];
    }

    protected getSubTypes(): SubTypeInfo[] {
        return [];
    }
}
