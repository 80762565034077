import {AuditedModel} from './audited.model';
import {UserModel} from '@model/user.model';
import {NotificationModel} from '@model/notification.model';

export class UserNotificationModel extends AuditedModel {
    public user: UserModel;
    public notification: NotificationModel;
    public readDate: Date;

    public constructor() {
        super();
    }
}
