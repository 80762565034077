import {AuditedModel} from './audited.model';
import {Deserializable} from './deserializable.model';

export class AssetModel extends AuditedModel implements Deserializable {

    public data: any[] = [];
    public name: string;
    public label: string;
    public type: string;
    public customerId: any;

    public constructor() {
        super();
    }

    public deserialize(input: any){
        Object.assign(this, input);
        return this;
    }
}
