import {Type} from '@angular/core';

import {BaseFilter} from './base/base.filter';

export class ChallengeFilter extends BaseFilter {

    public id: string;

    public constructor() {
        super();
    }

    protected getType(): Type<BaseFilter> {
        return ChallengeFilter;
    }
}
