import {AuditedModel} from './audited.model';

export class GroupModel extends AuditedModel {

    public name: string;

    public constructor() {
        super();
    }
}
