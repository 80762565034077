import {BaseModel} from './base/base.model';

export class ProfileModel extends BaseModel {
    public profileRole: string;
    public profileName: string;

    public constructor() {
        super();
    }
}
