import {Type} from '@angular/core';

import {BaseFilter} from './base/base.filter';
import {RoleEnum} from '@model/enums/role.enum';
import {StatusEnum} from '@model/enums/status.enum';
import {PermissionTypeEnum} from '@model/enums/permission-ype.enum';

export class UserFilter extends BaseFilter {

    public email: string;

    public name: string;

    public fiscalNumber: number;

    public geoCountyId: string;

    public geoPlaceId: string;

    public postalCode: string;

    public profileRole: RoleEnum;

    public status: StatusEnum;

    public permissionType: PermissionTypeEnum;

    public constructor() {
        super();
    }

    protected getType(): Type<BaseFilter> {
        return UserFilter;
    }
}
