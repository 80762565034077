import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CrudService, EnumInfo, SubTypeInfo} from './crud.service';
import {GeoCountyModel} from '@model/geocounty.model';
import {GeoCountyFilter} from '@filter/geocounty.filter';

@Injectable()
export class GeoCountyService extends CrudService<GeoCountyModel, GeoCountyFilter> {
    protected entityClass = GeoCountyModel;

    protected filterClass = GeoCountyFilter;

    protected entityPath = '/geocounty';

    public constructor(protected httpClient: HttpClient) {
        super(httpClient);
    }

    protected getEnums(): EnumInfo[] {
        return [];
    }

    protected getSubTypes(): SubTypeInfo[] {
        return [];
    }
}
