export class LoginRoutingNames {
    public static readonly NAME_BASE = '';
    public static readonly NAME_LOGIN = 'login';
    public static readonly NAME_AUTH = 'auth';
    public static readonly NAME_RECOVER = 'recover';
    public static readonly NAME_REGISTER = 'register';
    public static readonly NAME_RESET = 'reset';

    public static readonly ROUTE_LOGIN = `/${LoginRoutingNames.NAME_AUTH}/${LoginRoutingNames.NAME_LOGIN}`;
    public static readonly ROUTE_RECOVER = `/${LoginRoutingNames.NAME_AUTH}/${LoginRoutingNames.NAME_RECOVER}`;
    public static readonly ROUTE_RESET = `/${LoginRoutingNames.NAME_AUTH}/${LoginRoutingNames.NAME_RESET}`;
}
