export class AdministrationRoutingNames {
    public static readonly NAME_BASE = '';
    public static readonly NAME_ADMINISTRATION = 'administracao';
    public static readonly NAME_USER_MANAGEMENT = 'gestao-utilizadores';
    public static readonly NAME_ADDRESS_MANAGEMENT = 'gestao-morada';
    public static readonly NAME_ASSETS_MANAGEMENT = 'assets';
    public static readonly NAME_CREATE_USER = 'criar-utilizador';
    public static readonly NAME_EDIT_USER = 'editar-utilizador';
    public static readonly NAME_EDIT_ADDRESS = 'edit-address';
    public static readonly NAME_EDIT_ASSETS = 'edit-assets';
    public static readonly NAME_CREATE_ADDRESS = 'create-address';
    public static readonly NAME_CREATE_ASSETS = 'create-assets';
    public static readonly NAME_MONITORING_MANAGEMENT = 'monitoring';

    public static readonly NAME_PARAM_ID = 'id';

    public static readonly ROUTE_BASE = `/${AdministrationRoutingNames.NAME_BASE}`;
    public static readonly ROUTE_ADMINISTRATION = `/${AdministrationRoutingNames.NAME_ADMINISTRATION}`;
    public static readonly ROUTE_ADMINISTRATION_USER_MANAGEMENT = `${AdministrationRoutingNames.ROUTE_ADMINISTRATION}/${AdministrationRoutingNames.NAME_USER_MANAGEMENT}`;
    public static readonly ROUTE_ADMINISTRATION_CREATE_USER = `${AdministrationRoutingNames.ROUTE_ADMINISTRATION}/${AdministrationRoutingNames.NAME_USER_MANAGEMENT}/${AdministrationRoutingNames.NAME_CREATE_USER}`;
    public static readonly ROUTE_ADMINISTRATION_EDIT_USER = `${AdministrationRoutingNames.ROUTE_ADMINISTRATION}/${AdministrationRoutingNames.NAME_USER_MANAGEMENT}/${AdministrationRoutingNames.NAME_EDIT_USER}/:${AdministrationRoutingNames.NAME_PARAM_ID}`;
    public static readonly ROUTE_ADMINISTRATION_MONITORING_MANAGEMENT = `${AdministrationRoutingNames.ROUTE_ADMINISTRATION}/${AdministrationRoutingNames.NAME_MONITORING_MANAGEMENT}`;


    public static readonly ROUTE_ADMINISTRATION_ADDRESS_MANAGEMENT = `${AdministrationRoutingNames.ROUTE_ADMINISTRATION}/${AdministrationRoutingNames.NAME_ADDRESS_MANAGEMENT}`;
    public static readonly ROUTE_ADMINISTRATION_ASSETS_MANAGEMENT = `${AdministrationRoutingNames.ROUTE_ADMINISTRATION}/${AdministrationRoutingNames.NAME_ASSETS_MANAGEMENT}`;
    public static readonly ROUTE_ADMINISTRATION_CREATE_ADDRESS = `${AdministrationRoutingNames.ROUTE_ADMINISTRATION}/${AdministrationRoutingNames.NAME_ADDRESS_MANAGEMENT}/${AdministrationRoutingNames.NAME_CREATE_ADDRESS}`;
    public static readonly ROUTE_ADMINISTRATION_CREATE_ASSETS = `${AdministrationRoutingNames.ROUTE_ADMINISTRATION}/${AdministrationRoutingNames.NAME_ASSETS_MANAGEMENT}/${AdministrationRoutingNames.NAME_CREATE_ASSETS}`;
    public static readonly ROUTE_ADMINISTRATION_EDIT_ADDRESS = `${AdministrationRoutingNames.ROUTE_ADMINISTRATION}/${AdministrationRoutingNames.NAME_ADDRESS_MANAGEMENT}/${AdministrationRoutingNames.NAME_EDIT_ADDRESS}/:${AdministrationRoutingNames.NAME_PARAM_ID}`;
    public static readonly ROUTE_ADMINISTRATION_EDIT_ASSETS = `${AdministrationRoutingNames.ROUTE_ADMINISTRATION}/${AdministrationRoutingNames.NAME_ASSETS_MANAGEMENT}/${AdministrationRoutingNames.NAME_EDIT_ASSETS}/:${AdministrationRoutingNames.NAME_PARAM_ID}`;

}
