import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CrudService, EnumInfo, SubTypeInfo} from './crud.service';
import {Observable} from 'rxjs';
import {environment} from '@environments/environment';
import {catchError, map} from 'rxjs/operators';
import {AuctionModel} from '@model/auction.model';
import {AuctionFilter} from '@filter/auction.filter';
import {OffersModel} from '@model/offers.model';
import {OffersFilter} from '@filter/offers.filter';

@Injectable()
export class OffersService extends CrudService<OffersModel, OffersFilter> {
    protected entityClass = OffersModel;

    protected filterClass = OffersFilter;

    protected entityPath = 'offer';

    public cache = {};

    public constructor(protected httpClient: HttpClient) {
        super(httpClient);
    }

    protected getEnums(): EnumInfo[] {
        return [];
    }

    protected getSubTypes(): SubTypeInfo[] {
        return [];
    }

    public listAll(): Observable<OffersModel[]> {
        return this.httpClient.get<OffersModel[]>(environment.apiBackOfficeUrl + `${this.entityPath}`).pipe(
            map((data: OffersModel[]) => data.map(element => element)),
            catchError(error => this.handleExceptions(error)),
        );
    }

    findAll(): Observable<any> {
        return this.httpClient.get(environment.apiBackOfficeUrl + `${this.entityPath}`);
    }

    findAllById(id: any): Observable<any> {
        return this.httpClient.get(environment.apiBackOfficeUrl + `${this.entityPath}/by-id/${id}`);
    }

    public get(entityId: string): Observable<OffersModel> {
        return this.httpClient.get<OffersModel>(environment.apiBackOfficeUrl + `${this.entityPath}/${entityId}`).pipe(
            map((data: OffersModel) => this.mapEntity(data)),
            catchError(error => this.handleExceptions(error)),
        );
    }

    public save(entity: OffersModel): Observable<OffersModel> {
        return this.httpClient.post<OffersModel>(environment.apiBackOfficeUrl + this.entityPath, entity).pipe(
            map((data: OffersModel) => this.mapEntity(data)),
            catchError(error => this.handleExceptions(error)),
        );
    }

    public bid(entity: any): Observable<any> {
        return this.httpClient.post<any>(environment.apiBackOfficeUrl + 'bids', entity).pipe(
            map((data: any) => this.mapEntity(data)),
            catchError(error => this.handleExceptions(error)),
        );
    }

    findAllBidsByIdUser(id: any): Observable<any> {
        return this.httpClient.get(environment.apiBackOfficeUrl + `bids/by-user/${id}`);
    }

    public update(entity: OffersModel): Observable<OffersModel> {
        return this.httpClient.put<OffersModel>(environment.apiBackOfficeUrl + this.entityPath, entity).pipe(
            map((data: OffersModel) => this.mapEntity(data)),
            catchError(error => this.handleExceptions(error)),
        );
    }
}
