export class NotificationsRoutingNames {
    public static readonly NAME_BASE = '';
    public static readonly CREATE = 'new';
    public static readonly NAME_NOTIFICATIONS = 'notificacoes';

    public static readonly ROUTE_BASE = `/${NotificationsRoutingNames.NAME_BASE}`;
    public static readonly ROUTE_NOTIFICATIONS = `/${NotificationsRoutingNames.NAME_NOTIFICATIONS}`;


    public static readonly CREATE_NOTIFICATIONS = `/${NotificationsRoutingNames.NAME_NOTIFICATIONS}/${NotificationsRoutingNames.CREATE}`;
}
