import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {LoaderService} from '@service/loader.service';

@Component({
    selector: 'aud-loader',
    templateUrl: './app-loader.component.html',
    styleUrls: ['./app-loader.component.scss']
})
export class AppLoaderComponent implements OnInit, OnDestroy {

    public isLoading: boolean = false;

    private subscription: Subscription;

    public constructor(
        private loaderService: LoaderService,
    ) { }

    public ngOnDestroy(): void {
        if (this.subscription != null) {
            this.subscription.unsubscribe();
        }
    }

    public ngOnInit(): void {
        this.subscription = this.loaderService.isLoading.subscribe(value => this.isLoading = value);
    }
}
