import {NgModule} from '@angular/core';

import {SafeUrlPipe} from '@util/pipes/safe-url.pipe';
import {DateFormatPipe} from '@util/pipes/date-format.pipe';

import {VarDirective} from './ng-var.directive';


@NgModule({
    declarations: [
        SafeUrlPipe,
        DateFormatPipe,
        VarDirective,
    ],
    imports: [],
    exports: [
        SafeUrlPipe,
        DateFormatPipe,
        VarDirective,
    ]
})

export class UtilModule { }
