import {Type} from '@angular/core';

import {BaseFilter} from './base/base.filter';

export class ProfileFilter extends BaseFilter {
    public profileId: string;
    public profileRole: string;

    public constructor() {
        super();
    }

    protected getType(): Type<BaseFilter> {
        return ProfileFilter;
    }
}
