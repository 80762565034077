<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="col-12 aud-pt-30">
            <h2 *ngIf="this.id !== ''">{{'COMPONENTS.APP_HEADER.SUB_MENU.URBIS_LABEL' | translate}}</h2>
            <h2 *ngIf="this.id === ''">{{'COMPONENTS.APP_HEADER.SUB_MENU.URBIS' | translate}}</h2>
            <div class="aud-underline aud-mb-30"></div>

            <div class="aud-card form-control-wrapper aud-border-radius-15 aud-pt-25 aud-pl-25 aud-pr-25">
                <aud-table [users]="entities" [cols]="entitiesProps" [tableResultsCount]="entitiesCount"
                           (loadSearch)="lazySearch($event)"
                           [editRoute]=""
                           [toEdit]="false"
                ></aud-table>
            </div>
        </div>
    </div>
</div>
