import {Type} from '@angular/core';

import {BaseFilter} from './base/base.filter';

export class NotificationFilter extends BaseFilter {

    public message: string;

    public constructor() {
        super();
    }

    protected getType(): Type<BaseFilter> {
        return NotificationFilter;
    }
}
