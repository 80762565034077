import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'aud-user-card',
    templateUrl: './aud-user-card.component.html',
    styleUrls: ['./aud-user-card.component.scss']
})
export class AudUserCardComponent implements OnInit {
    public constructor() { }

    public ngOnInit(): void {
    }
}
