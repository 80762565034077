import {BaseModel} from './base/base.model';
import {Deserializable} from './deserializable.model';

export class GeoPlaceModel extends BaseModel implements Deserializable  {
    public description: string;

    public constructor() {
        super();
    }

    public deserialize(input: any){
        Object.assign(this, input);
        return this;
    }
}
