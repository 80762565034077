import {Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validators
} from '@angular/forms';

@Component({
    selector: 'aud-checkbox',
    templateUrl: './aud-checkbox.component.html',
    styleUrls: ['./aud-checkbox.component.scss'],
    providers: [
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AudCheckboxComponent), multi: true},
        {provide: NG_VALIDATORS, useExisting: forwardRef(() => AudCheckboxComponent), multi: true},
    ],
})
export class AudCheckboxComponent implements OnInit, OnChanges, ControlValueAccessor {
    @Input() public name: string;
    @Input() public label: string;
    @Input() public readOnly: boolean = false;
    @Input() public showErrors: boolean = true;
    @Input() public required: boolean = false;
    @Input() public displayRequired: boolean = true;
    @Input() public indeterminate: boolean = false;
    @Input() public checked: boolean = false;
    @Input() public customLabelContent: boolean = false;
    @Input() public customFormGroupClass: string = '';
    @Input() public customLabelClass: string;
    @Input() public externalRequiredErrorMessage: string;

    @Output() public changeEvent = new EventEmitter<any>();

    // tslint:disable-next-line:variable-name
    private _value: any;

    public readonly guid: string;

    public formGroup: FormGroup;
    public propagateChange: any = () => {};
    public propagateTouch: any = () => {};
    public propagateValidatorChange: any = () => {};

    public constructor( ) {}

    public ngOnInit() {
        this.initForm();
    }

    public onChange() {
        this._value = this.input.value;
        this.propagateChange(this._value);
        this.changeEvent.emit(this._value);
    }

    /////////////////////////////////////////////
    // FORM
    /////////////////////////////////////////////
    private initForm() {
        const validators = [];

        if (this.required) {
            validators.push(Validators.required);
        }

        this.formGroup = new FormGroup({
            checkbox: new FormControl({ value: this._value, disabled: this.readOnly }, { validators }),
        });
    }

    public get input(): FormControl {
        if (this.formGroup) {
            return this.formGroup.get('checkbox') as FormControl;
        }
    }

    /////////////////////////////////////////////
    // Interfaces implementation
    /////////////////////////////////////////////
    public writeValue(value: any) {
        this._value = value;
        this.input.setValue(this._value);
    }

    public registerOnChange(fn: () => void) {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled == null) {
            isDisabled = false;
        }

        this.readOnly = isDisabled;

        if (this.input) {
            isDisabled ? this.input.disable() : this.input.enable();
        }
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.propagateValidatorChange = fn;
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        return this.formGroup.invalid ? this.input.errors : null;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        for (const propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                if (propName === 'readOnly') {
                    const change = changes[propName];
                    const previousValue = change.previousValue;
                    const currentValue = change.currentValue;

                    if (previousValue !== currentValue) {
                        this.setDisabledState(currentValue);
                    }
                }

                if (propName === 'required') {
                    const change = changes[propName];
                    const currentValue = change.currentValue;

                    if (this.input) {
                        if (currentValue) {
                            this.input.setValidators(Validators.required);
                        } else {
                            this.input.clearValidators();
                        }
                    }
                }

                if (propName === 'checked') {
                    this._value = this.checked;

                    if (this.input) {
                        this.writeValue(this.checked);
                    }
                }
            }
        }
    }
}
