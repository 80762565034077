import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CrudService, EnumInfo, SubTypeInfo} from './crud.service';
import {UserNotificationModel} from '@model/user-notification.model';
import {UserService} from '@service/user.service';
import {UserNotificationFilter} from '@filter/user-notification.filter';
import {NotificationService} from '@service/notification.service';
import {Observable} from 'rxjs';
import {environment} from '@environments/environment';

@Injectable()
export class UserNotificationService extends CrudService<UserNotificationModel, UserNotificationFilter> {
    protected entityClass = UserNotificationModel;

    protected filterClass = UserNotificationFilter;

    protected entityPath = '/userNotification';

    public constructor(protected httpClient: HttpClient, protected userService: UserService, protected notificationService: NotificationService) {
        super(httpClient);
    }

    protected getEnums(): EnumInfo[] {
        return [];
    }

    protected getSubTypes(): SubTypeInfo[] {
        return [
            new SubTypeInfo('user', this.userService),
            new SubTypeInfo('notification', this.notificationService)
        ];
    }

    public readUserNotification(id: string): Observable<void> {
        return this.httpClient.post<void>(environment.apiUrl + this.entityPath + '/readUserNotification', id);
    }

}
