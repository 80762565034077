import {BaseModel} from './base/base.model';

export class UrbisModel extends BaseModel {

    public value: string;
    public description: string;

    public constructor() {
        super();
    }
}
