<ng-container [formGroup]="formGroup">
    <div class="form-group form-check" [ngClass]="[customFormGroupClass]">
        <div class="custom-control custom-checkbox custom-control-inline">
            <input class="custom-control-input aud-border-radius-15"
                type="checkbox"
                [id]="name"
                [name]="name"
                [ngClass]="{ 'is-invalid': !readOnly && showErrors && (input.invalid) }"
                [readOnly]="readOnly"
                (ngModelChange)="onChange()"
                formControlName="checkbox"/>
            <ng-content *ngIf="customLabelContent; else defaultLabel"></ng-content>
            <ng-template #defaultLabel>
                  <label class="custom-control-label" [for]="name">{{label | translate}}</label>
            </ng-template>
        </div>
    </div>
</ng-container>
