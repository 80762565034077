import {Type} from '@angular/core';

export enum SortOrder {
    ASCENDING = 'ASCENDING',
    DESCENDING = 'DESCENDING'
}

export abstract class BaseFilter {

    public pageSize = 10;

    public sort: SortOrder = SortOrder.ASCENDING;

    public sortField: string;

    public start = 0;

    public constructor() { }

    public clone() {
        const type = this.getType();
        return Object.assign(new type(), this);
    }

    protected abstract getType(): Type<BaseFilter>;
}
