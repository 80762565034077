
<ng-template *ngTemplateOutlet="audTableFilters"></ng-template>
<ngx-datatable #datatable
    (page)="lazySearch($event)"
    [rows]="users"
    [columns]="cols"
    [columnMode]="'force'"
    [selectionType]="selectionType"
    [selected]="selected"
    [externalPaging]="true"
    [limit]="ipp"
    [count]="tableResultsCount"
    [footerHeight]="'auto'"
    [headerHeight]="headerHeight"
    [rowHeight]="'auto'"
    (select)="onSelect($event)">

    <ngx-datatable-column [width]="30"
        [sortable]="false"
        [canAutoResize]="false"
        [draggable]="false"
        [resizeable]="false">
        <ng-template ngx-datatable-cell-template
            let-rowIndex="rowIndex"
            let-value="value"
            let-isSelected="isSelected"
            let-onCheckboxChangeFn="onCheckboxChangeFn">
            <div class="form-check datatable-checkbox p-0">
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input class="custom-control-input aud-border-radius-15"
                        type="checkbox"
                        [id]="'rowCheckbox' + rowIndex"
                        name="rowCheckbox"
                        [checked]="isSelected"
                        (change)="onCheckboxChangeFn($event)"/>
                    <label class="custom-control-label" [for]="'rowCheckbox' + rowIndex"></label>
                </div>
            </div>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngFor="let col of cols" [prop]="col.prop" [name]="col.name" [minWidth]="100">
        <ng-template ngx-datatable-cell-template
            let-rowIndex="rowIndex"
            let-row="row"
            let-value="value">
            <div class="grey-box-shadow" *ngIf="col.prop === 'addresses'" >
                <i class="pi pi-home" [ngbPopover]="address" triggers="mouseenter:mouseleave"></i>
                {{ getNumberOfAddresses(coerceEntity(row)) }}
            </div>
            <div *ngIf="col.prop !== 'status' && col.prop !== 'addresses' && col.type !== 'date'">
                {{ value }}
            </div>
            <div *ngIf="col.type == 'date'">
                {{value  | date:'yyyy-MM-dd HH:MM:SS'}}
            </div>
            <div *ngIf="col.prop === 'status'" class="status-box-shadow" [ngClass]="{'active' : value === 'ACTIVE', 'inactive' : value === 'INACTIVE'}">
                {{ statusEnum.getLabelKey(value) | translate }}
            </div>
            <ng-template #address>
                <p>{{ getAddressTooltipContent(coerceEntity(row)) }}</p>
            </ng-template>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [width]="45"
        [sortable]="false"
        [canAutoResize]="false"
        [draggable]="false"
        [resizeable]="false"
    *ngIf="toEdit">
        <ng-template ngx-datatable-cell-template
            let-rowIndex="rowIndex"
            let-row="row">
            <div class="p-0">
                <a (click)="edit(row.id)">
                    <i class="pi pi-ellipsis-v"></i>
                </a>
            </div>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-footer>
        <ng-template ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-selectedCount="selectedCount"
            let-curPage="curPage"
            let-offset="offset">

            <div class="" style="min-width: 65px">
                <aud-combobox [items]="[10, 25, 50, 100]"
                    [clearable]="false"
                    [extraFormGroupClass]="'mb-0 aud-mr-15'"
                    [(ngModel)]="ipp"
                    (ngModelChange)="pageLimit($event)">
                </aud-combobox>
            </div>

            <div class="aud-table-footer-text">
                A mostrar {{getFromRecordCount(offset, pageSize)}} - {{getToRecordCount(pageSize, curPage, rowCount)}} de {{rowCount}} resultados
            </div>

            <datatable-pager [pagerLeftArrowIcon]="'pi pi-angle-left'"
                [pagerRightArrowIcon]="'pi pi-angle-right'"
                [pagerPreviousIcon]="'pi pi-angle-double-left'"
                [pagerNextIcon]="'pi pi-angle-double-right'"
                [page]="curPage"
                [size]="pageSize"
                [count]="rowCount"
                [hidden]="!((rowCount / pageSize) > 1)"
                (change)="datatable.onFooterPage($event)">
            </datatable-pager>
        </ng-template>
    </ngx-datatable-footer>
</ngx-datatable>
