import {Type} from '@angular/core';

import {BaseFilter} from './base/base.filter';
import {UserModel} from '@model/user.model';

export class UserNotificationFilter extends BaseFilter {

    user: string;

    public constructor() {
        super();
    }

    protected getType(): Type<BaseFilter> {
        return UserNotificationFilter;
    }
}
