import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
    name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
    public transform(value: Date): string {
        const datePipe = new DatePipe('pt-PT');
        return datePipe.transform(value);
    }
}
