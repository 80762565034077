import {Type} from '@angular/core';

import {BaseFilter} from './base/base.filter';

export class GeoPostalCodeFilter extends BaseFilter {

    public postalCode: string;
    public constructor() {
        super();
    }

    protected getType(): Type<BaseFilter> {
        return GeoPostalCodeFilter;
    }
}
