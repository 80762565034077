import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CrudService, EnumInfo, SubTypeInfo} from './crud.service';
import {Observable} from 'rxjs';
import {environment} from '@environments/environment';
import {ChallengeModel} from '@model/challenge.model';
import {catchError, map} from 'rxjs/operators';
import {ChallengeFilter} from '@filter/challenge.filter';

@Injectable()
export class ChallengeService extends CrudService<ChallengeModel, ChallengeFilter> {
    protected entityClass = ChallengeModel;

    protected filterClass = ChallengeFilter;

    protected entityPath = 'challenge';

    public cache = {};

    public constructor(protected httpClient: HttpClient) {
        super(httpClient);
    }

    protected getEnums(): EnumInfo[] {
        return [];
    }

    protected getSubTypes(): SubTypeInfo[] {
        return [];
    }

    public listAll(): Observable<ChallengeModel[]> {
        return this.httpClient.get<ChallengeModel[]>(environment.apiBackOfficeUrl + `${this.entityPath}`).pipe(
            map((data: ChallengeModel[]) => data.map(element => element)),
            catchError(error => this.handleExceptions(error)),
        );
    }

    findAll(): Observable<any> {
        return this.httpClient.get(environment.apiBackOfficeUrl + `${this.entityPath}`);
    }

    public get(entityId: string): Observable<ChallengeModel> {
        return this.httpClient.get<ChallengeModel>(environment.apiBackOfficeUrl + `${this.entityPath}/${entityId}`).pipe(
            map((data: ChallengeModel) => this.mapEntity(data)),
            catchError(error => this.handleExceptions(error)),
        );
    }
}
