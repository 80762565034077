import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CrudService, EnumInfo, SubTypeInfo} from './crud.service';
import {ProfileModel} from '@model/profile.model';
import {ProfileFilter} from '@filter/profile.filter';
import {RoleEnum} from '@model/enums/role.enum';

@Injectable()
export class ProfileService extends CrudService<ProfileModel, ProfileFilter> {
    protected entityClass = ProfileModel;

    protected filterClass = ProfileFilter;

    protected entityPath = '/profile';

    public constructor(protected httpClient: HttpClient) {
        super(httpClient);
    }

    protected getEnums(): EnumInfo[] {
        return [
            new EnumInfo('profileRole', RoleEnum)
        ];
    }

    protected getSubTypes(): SubTypeInfo[] {
        return [];
    }
}
