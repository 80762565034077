import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {CrudService, EnumInfo, SubTypeInfo} from './crud.service';
import {ProfileService} from '@service/profile.service';
import {AddressService} from '@service/address.service';
import {environment} from '@environments/environment';
import {AssetModel} from '@model/asset.model';
import {AssetFilter} from '@filter/asset.filter';
import {CustomerModel} from '@model/customer.model';

@Injectable()
export class AssetsService extends CrudService<AssetModel, AssetFilter> {
    protected filterClass = AssetFilter;
    protected entityClass = AssetModel;
    protected entityPath = '/assets';

    public constructor(protected httpClient: HttpClient, protected profileService: ProfileService, protected addressService: AddressService) {
        super(httpClient);
    }

    public getAssets(): Observable<any> {
        return this.httpClient.get<any>(environment.apiUrl + `${this.entityPath}`).pipe(
            map((data: any) => data),
            catchError(error => this.handleExceptions(error))
        );
    }

    public relations(id: string): Observable<any> {
        return this.httpClient.get<any>(environment.apiUrl + `${this.entityPath}/relations/${id}`).pipe(
            map((data: any) => data),
            catchError(error => this.handleExceptions(error))
        );
    }

    protected getEnums(): EnumInfo[] {
        return [];
    }

    protected getSubTypes(): SubTypeInfo[] {
        return [];
    }

    public assign(fromAssetId: string, toAssetId: string): Observable<any> {
        return this.httpClient.post<any>(environment.apiUrl + this.entityPath + `/assign/${fromAssetId}/asset/${toAssetId}`, {}).pipe(
            map((data: any) => this.mapEntity(data)),
            catchError(error => this.handleExceptions(error)),
        );
    }

    public disassociate(fromAssetId: string, toAssetId: string): Observable<any> {
        return this.httpClient.delete<any>(environment.apiUrl + this.entityPath + `/disassociate/${fromAssetId}/asset/${toAssetId}`, {}).pipe(
            map((data: any) => this.mapEntity(data)),
            catchError(error => this.handleExceptions(error)),
        );
    }

    public unAssign(assetId: string): Observable<any> {
        return this.httpClient.delete<any>(environment.apiUrl + `/customers/asset/${assetId}`, {}).pipe(
            map((data: any) => this.mapEntity(data)),
            catchError(error => this.handleExceptions(error)),
        );
    }

    public searchCustomer(filter: any): Observable<CustomerModel> {
        return this.httpClient.post<CustomerModel>(environment.apiUrl + `customers/search`, filter).pipe(
            map((data: any) => this.mapEntity(data)),
            catchError(error => this.handleExceptions(error)),
        );
    }

    public toAssign(customerId: string, toAssetId: string): Observable<any> {
        return this.httpClient.post<any>(environment.apiUrl + `/customers/assign/${customerId}/asset/${toAssetId}`, {}).pipe(
            map((data: any) => this.mapEntity(data)),
            catchError(error => this.handleExceptions(error)),
        );
    }

    public customerById(customerId: string): Observable<any> {
        return this.httpClient.get<any>(environment.apiUrl + `/customers/by-id/${customerId}`).pipe(
            map((data: any) => this.mapEntity(data)),
            catchError(error => this.handleExceptions(error)),
        );
    }

}
