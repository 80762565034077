import {Component, ContentChild, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {UserModel} from '@model/user.model';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {AudTableFiltersDirective} from '@component/aud-table/aud-table.directives';
import {UrbisModel} from '@model/urbis.model';
import {UserService} from '@service/user.service';
import {UrbisService} from '@service/urbis.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {UrbisFilter} from '@filter/urbis.filter';
import {SortOrder} from '@filter/base/base.filter';

@Component({
    selector: 'aud-urbis',
    templateUrl: './aud-urbis.component.html',
    styleUrls: ['./aud-urbis.component.scss'],
    animations: [
        trigger('slideInOut',
            [
                transition(':enter',
                    [
                        style({height: 0, opacity: 0}),
                        animate('.15s ease-in-out',
                            style({height: '*', opacity: 1})),
                    ]
                ),
                transition(':leave',
                    [
                        style({height: '*', opacity: 1}),
                        animate('.15s ease-in-out',
                            style({height: 0, opacity: 0})
                        ),
                    ]
                )
            ]
        )
    ]
})
export class AudUrbisComponent implements OnInit {

    @ContentChild(AudTableFiltersDirective, {read: TemplateRef}) public audTableFilters: TemplateRef<any>;
    @ViewChild(DatatableComponent) private table: DatatableComponent;
    @Input() public id: any = '';

    public filterParameter: UrbisFilter = new UrbisFilter();
    private user: UserModel;
    public entitiesProps = [
        {prop: 'idUserUrbis', name: '#'},
        {prop: 'dataUrbis', name: 'Data', type: 'date'},
        {prop: 'description', name: 'Descrição'},
        {prop: 'prizeUrbis', name: 'Urbis'},
        {prop: 'balance', name: 'Saldo'},
    ];

    public labelSection = null;
    public entities: UrbisModel [];
    public entitiesCount: number = 1;

    public constructor(
        private userService: UserService,
        private urbisService: UrbisService,
    ) {
    }

    public ngOnInit(): void {
        this.user = this.userService.currentUser;
        this.search();
    }

    public search(): void {
        this.filterParameter.idUserUrbis = this.id !== '' ? this.id : this.user.id;
        this.filterParameter.sort = SortOrder.DESCENDING;
        this.filterParameter.sortField = 'dataUrbis';
        this.urbisService.count(this.filterParameter).subscribe((value: any) => {
            this.entitiesCount = value;
            this.urbisService.search(this.filterParameter).subscribe(entity => {
                this.entities = [];
                this.entities = entity;
            });
        });
        // setInterval(() => {
            /*this.urbisService.readWallet(this.id !== '' ? this.id : this.user.id).subscribe(entity => {
                entity.id = this.id !== '' ? this.id : this.user.id;
                entity.totalElements = 1;
                entity.totalPages = 1;
                this.entities = [];
                this.entities.push(entity);
            });*/
        // }, 1000);
    }

    public lazySearch(event: any) {
        this.search();
    }
}
