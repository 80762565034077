import {Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {RoleEnum} from '@model/enums/role.enum';
import {StatusEnum} from '@model/enums/status.enum';
import {UserModel} from '@model/user.model';
import {AdministrationRoutingNames} from '@modules/administration/administration-routing.names';
import {ColumnMode, DatatableComponent, SelectionType} from '@swimlane/ngx-datatable';

import {AudTableFiltersDirective} from './aud-table.directives';

@Component({
    selector: 'aud-table',
    templateUrl: './aud-table.component.html',
    styleUrls: ['./aud-table.component.scss']
})
export class AudTableComponent implements OnInit {
    @ContentChild(AudTableFiltersDirective, {read: TemplateRef}) public audTableFilters: TemplateRef<any>;

    @ViewChild(DatatableComponent) private table: DatatableComponent;

    @Input() public tableResultsCount: number = 0;
    @Input() public users = [];
    @Input() public cols = [];
    @Input() public headerHeight: any = 'auto';
    @Input() public selectionType: any = 'checkbox';
    @Input() public editRoute: any = '';
    @Input() public searchTable: boolean = false;
    @Input() public toEdit: boolean = true;
    @Input() public toDelete: boolean = false;
    @Input() public toSelect: boolean = false;

    @Output() public loadSearch: EventEmitter<any> = new EventEmitter<any>();
    @Output() public selectedData: EventEmitter<any> = new EventEmitter<any>();

    public administrationRoutingNames = AdministrationRoutingNames;

    public ColumnMode = ColumnMode;
    public SelectionType = SelectionType;
    public ipp = 10;

    public selected = [];
    public filteredData = this.users;
    public tableFilter: string = '';

    public statusEnum = StatusEnum;

    public constructor(
        public router: Router,
    ) {
    }

    public ngOnInit(): void {
    }

    public lazySearch(event: any): void {
        event.offset = this.getOffset(event.offset, event.pageSize);
        this.loadSearch.emit(event);
    }

    public edit(id: any): void {
        if (id?.id) {
            this.router.navigate([this.editRoute.replace(`:${this.administrationRoutingNames.NAME_PARAM_ID}`, id.id)]);
        } else {
            this.router.navigate([this.editRoute.replace(`:${this.administrationRoutingNames.NAME_PARAM_ID}`, id)]);
        }
    }

    public delete(id: any): void {
        this.loadSearch.emit(id);
    }

    public coerceEntity(entity: any): any {
        return entity;
    }

    public getNumberOfAddresses(userModel: UserModel): number {
        return userModel.addresses.length;
    }

    public getAddressTooltipContent(userModel: UserModel): string {
        let stringToReturn = '';
        if (RoleEnum.OPERATIONAL === RoleEnum.enum(userModel.profile?.profileRole.toString()) || RoleEnum.TECHNICIAN === RoleEnum.enum(userModel.profile?.profileRole.toString())) {
            userModel.addresses.forEach(value => {
                stringToReturn = stringToReturn.concat(value?.address.geoPostalCode?.geoCountyDesc, ', ', value?.address.geoPostalCode?.geoPlaceDesc, '\n');
            });
        }

        if (RoleEnum.FINAL_USER === RoleEnum.enum(userModel.profile?.profileRole.toString())) {
            userModel.addresses.forEach(value => {
                stringToReturn = ''; // stringToReturn.concat(value.geoCountryDesc, ', ', value.street, ', ', value.door, ' ', value.floor, ', ', value?.geoPostalCode?.postalCode, '\n');
            });
        }

        return stringToReturn;
    }

    public onSelect({selected}) {
        this.selected.splice(0, this.selected.length);
        this.selected.push(...selected);
        this.selectedData.emit(selected);
    }

    public pageLimit(num: number) {
        this.ipp = Number(num);
        if (this.users) {
            this.table.pageSize = Number(num);
            this.loadSearch.emit({pageSize: this.table.pageSize, offset: this.table.offset});
        }
    }

    public getFromRecordCount(offset: number, pageSize: number): number {
        return !offset ? 1 : (offset * pageSize) + 1;
    }

    public getOffset(offset: number, pageSize: number): number {
        return !offset ? 0 : (offset * pageSize);
    }

    public getToRecordCount(pageSize: number, curPage: number, rowCount: number): number {
        return pageSize * curPage < rowCount ? pageSize * curPage : rowCount;
    }

    public filterTable(term: string) {
        const search = term.toLowerCase();
        const filteredData = this.filteredData.filter((d) => {
            let res = false;

            Object.keys(d).map(key => {
                if (d[key].toLowerCase().indexOf(search) !== -1) {
                    res = true;
                }
            });

            return res;
        });

        this.users = filteredData;
        this.table.offset = 0;
    }

    public select(selected): void {
        console.log(selected);
        this.selected.splice(0, this.selected.length);
        this.selected.push(...selected);
        this.selectedData.emit(selected);
    }
}
