import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[ngVar]'
})
export class VarDirective {
    public context: any = {};

    public constructor(
        private vcRef: ViewContainerRef,
        private templateRef: TemplateRef<any>,
    ) { }

    @Input()
    public set ngVar(context: any) {
        this.context.$implicit = this.context.ngVar = context;
        this.updateView();
    }

    public updateView() {
        this.vcRef.clear();
        this.vcRef.createEmbeddedView(this.templateRef, this.context);
    }
}
