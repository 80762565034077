import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class LoaderService {
    public isLoading = new Subject<boolean>();

    private count: number = 0;

    public show() {
        this.count++;
        this.isLoading.next(true);
    }

    public hide() {
        this.count--;

        if (this.count === 0) {
            this.isLoading.next(false);
        }
    }
}
