import {GeoPostalCodeModel} from './geo-postal-code.model';
import {BaseModel} from '@model/base/base.model';
import {Deserializable} from './deserializable.model';

export class AddressModel extends BaseModel implements Deserializable {
    public street: string;
    public geoCountryId: string;
    public geoPostalCode: GeoPostalCodeModel;
    public door: string;
    public parish: string;
    public latitude: string;
    public longitude: string;
    public status: any;

    public constructor() {
        super();
    }

   public deserialize(input: any): this {
        return undefined;
    }

}
