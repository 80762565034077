import {BrowserModule} from '@angular/platform-browser';
import {DatePipe, registerLocaleData} from '@angular/common';
import localePt from '@angular/common/locales/pt-PT';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CalendarModule} from 'primeng/calendar';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {SplitterModule} from 'primeng/splitter';
import {GlobalErrorHandler} from '@util/GlobalErrorHandler';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {ModulesModule} from '@modules/modules.module';
import {LayoutModule} from '@layout/layout.module';
import {ComponentModule} from '@component/component.module';
import {UtilModule} from '@util/util.module';

import {AuthGuard} from '@guard/auth.guard';

import {AuthService} from '@service/auth.service';
import {UserService} from '@service/user.service';
import {NavigationService} from '@service/navigation.service';
import {GroupService} from '@service/group.service';
import {LoaderService} from '@service/loader.service';
import {ProfileService} from '@service/profile.service';
import {GeoPlaceService} from '@service/geoplace.service';
import {GeoCountyService} from '@service/geocounty.service';
import {GeoDistrictService} from '@service/geodistrict.service';

import {AuthInterceptor} from '@interceptor/auth.interceptor';
import {EnumInterceptor} from '@interceptor/enum.interceptor';
import {LoaderInterceptor} from '@interceptor/loader.interceptor';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AddressService} from '@service/address.service';
import {NotificationService} from '@service/notification.service';
import {UserNotificationService} from '@service/user-notification.service';
import {UrbisService} from '@service/urbis.service';
import {GeoPostalCodeService} from '@service/geopostal-code.service';
import {GeocountryService} from '@service/geocountry.service';
import {AssetsService} from '@service/assets.service';
import {ChipsModule} from 'primeng/chips';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ChallengeService} from '@service/challenge.service';
import {PrizeService} from '@service/prize.service';
import {AuctionService} from '@service/auction.service';
import {OffersService} from '@service/offers.service';


registerLocaleData(localePt);

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        HttpClientModule,
        LayoutModule,
        ModulesModule,
        ComponentModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CalendarModule,
        ChipsModule,
        UtilModule,
        NgbModule,
        SplitterModule,
        TranslateModule.forRoot({
            defaultLanguage: 'pt',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [ HttpClient ]
            }
        }),
    ],
    providers: [
        AuthService,
        DatePipe,
        UserService,
        NavigationService,
        AuthGuard,
        LoaderService,
        ProfileService,
        GeoPlaceService,
        GeoCountyService,
        GeoDistrictService,
        GroupService,
        AddressService,
        UrbisService,
        NotificationService,
        UserNotificationService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: EnumInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        MessageService,
        ConfirmationService,
        GeoPostalCodeService,
        GeocountryService,
        AssetsService,
        ChallengeService,
        PrizeService,
        AuctionService,
        OffersService
    ],
    bootstrap: [ AppComponent ]
})

export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
