import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthGuard} from '@guard/auth.guard';

import {AppLayoutComponent} from '@layout/app-layout/app-layout.component';

import {ModulesRoutingNames} from '@modules/modules-routing.names';
import {HomeRoutingNames} from '@modules/home/home-routing.names';
import {GroupRoutingNames} from '@modules/group/group-routing.names';
import {AdministrationRoutingNames} from '@modules/administration/administration-routing.names';
import {NotificationsRoutingNames} from '@modules/notifications/notifications-routing.names';
import {ProfileRoutingNames} from '@modules/profile/profile-routing.names';
import {MyUrbisRoutingNames} from '@modules/my-urbis/my-urbis-routing.names';
import {ChallengesRoutingNames} from '@modules/challenges/challenges-routing.names';
import {PrizesRoutingNames} from '@modules/prizes/prizes-routing.names';
import {AuctionsRoutingNames} from '@modules/auctions/auctions-routing.names';

const routes: Routes = [
    {
        path: ModulesRoutingNames.NAME_BASE, canActivate: [AuthGuard], component: AppLayoutComponent, children: [
            {path: '', redirectTo: HomeRoutingNames.ROUTE_HOME, pathMatch: 'full'},
            {
                path: HomeRoutingNames.NAME_HOME,
                loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
            },
            {
                path: GroupRoutingNames.NAME_GROUP,
                loadChildren: () => import('./group/group.module').then(m => m.GroupModule)
            },
            {
                path: AdministrationRoutingNames.NAME_ADMINISTRATION,
                loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule)
            },
            {
                path: NotificationsRoutingNames.NAME_NOTIFICATIONS,
                loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule)
            },
            {
                path: ProfileRoutingNames.NAME_PROFILE,
                loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
            },
            {
                path: MyUrbisRoutingNames.NAME_URBIS,
                loadChildren: () => import('./my-urbis/my-urbis.module').then(m => m.MyUrbisModule)
            },
            {
                path: AuctionsRoutingNames.NAME_AUCTIONS,
                loadChildren: () => import('./auctions/auctions.module').then(m => m.AuctionsModule)
            },
            {
                path: ChallengesRoutingNames.NAME_CHALLENGE,
                loadChildren: () => import('./challenges/challenges.module').then(m => m.ChallengesModule)
            },
            {
                path: PrizesRoutingNames.NAME_PRIZE,
                loadChildren: () => import('./prizes/prizes.module').then(m => m.PrizesModule)
            },
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ModulesRoutingModule {
}
