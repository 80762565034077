import {Component, OnDestroy, OnInit} from '@angular/core';
import {interval, Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';

import {AuthService} from '@service/auth.service';

import {LoginRoutingNames} from '@modules/login/login-routing.names';

@Component({
    selector: 'aud-app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

    private readonly tokenCheckInterval = 5 * 1000; // 5 seconds

    private sub: Subscription;
    private url: string = null;

    private checkToken = true;

    public constructor(
        private authService: AuthService,
        private translate: TranslateService,
        private router: Router,
        private location: Location,
    ) {
        // try to use the browser one, will fallback to default it not found
        translate.use(translate.getBrowserLang());

        router.events.subscribe(() => {
            //  Change flag based on URL to prevent redirect on recover page
            this.checkToken = this.url && (!this.url.includes(LoginRoutingNames.ROUTE_RECOVER) &&
                !this.url.includes(LoginRoutingNames.ROUTE_RESET) && !this.url.includes('/not-found'));
            this.url = location.path();
        });
    }

    public ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    public ngOnInit(): void {
        this.sub = interval(this.tokenCheckInterval).subscribe(val => {
            //  Check only if flag is true
            if (!this.authService.isAuthenticated() && this.checkToken) {
                this.sub.unsubscribe();
                this.authService.tokenUnauthorized();
                this.router.navigate(['/']);
            }
        });
    }
}
