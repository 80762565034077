<ng-container [formGroup]="formGroup">
    <div class="form-group" [ngClass]="[formGroupClass]">
        <label *ngIf="label" [for]="name">{{label | translate}} <ng-container *ngIf="required">(*)</ng-container></label>
        <div class="form-control-wrapper aud-border-radius-15 d-flex align-items-center">
            <input class="form-control aud-border-radius-15"
                [id]="name"
                [name]="name"
                [formControlName]="'input'"
                [type]="fieldType"
                [placeholder]="placeholder"
                [ngClass]="{ 'is-invalid': !readOnly && showErrors && (input.invalid) }"
                [readOnly]="readOnly"
                (ngModelChange)="onChange($event)"/>
            <ng-template *ngTemplateOutlet="audInputExtraButton"></ng-template>
        </div>
    </div>
</ng-container>
