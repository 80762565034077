export abstract class Enum {
    // tslint:disable-next-line:variable-name
    protected constructor(private _value: string) {
        this.value = _value;
        this.labelKey = Object.getPrototypeOf(this).constructor.getLabelKeyBase() + '.' + _value;
    }

    protected static LABEL_KEY_BASE: string = 'ENUM';

    protected readonly value: string;
    protected readonly labelKey: string;

    public static getLabelKeyBase() {
        return this.LABEL_KEY_BASE;
    }

    public static enums(): Enum[] {
        return Object.keys(this)
            .map(key => this[key])
            .filter(value => value instanceof Enum) as Enum[];
    }

    public static getLabelKey(value: Enum | string) {
        if (value) {
            const enumObj = value instanceof Enum ? value : this.enum(value);

            if (enumObj) {
                return enumObj.getLabelKey();
            }

            console.log('Enum.getLabelKey :: unknown label ' + value.toString());
            return 'UNKNOWN_ENUM_LABEL';
        }

        return '';
    }

    public static enum(key: string): Enum {
        if (key != null) {
            let valueToConvert = key;

            if (typeof key === 'object') {
                // tslint:disable-next-line:no-string-literal
                valueToConvert = key['value'];
            }

            return this.enums().find(value => value.value === valueToConvert);
        }

        return undefined;
    }

    public getValue(): string {
        return this.value;
    }

    public getLabelKey(): string {
        return this.labelKey;
    }

    public is(value: Enum | string) {
        const valueStr = value ? value.toString() : undefined;
        return this.value === valueStr;
    }

    public toString() {
        return String(this.value);
    }
}
