import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AppRoutingNames} from './app-routing.names';
import {LoginRoutingNames} from '@modules/login/login-routing.names';

const routes: Routes = [
    {
        path: AppRoutingNames.NAME_BASE,
        pathMatch: 'full',
        loadChildren: () => import('./modules/modules.module').then(m => m.ModulesModule)
    },
    {
        path: `${LoginRoutingNames.NAME_AUTH}`,
        loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
    },
    {path: '**', loadChildren: () => import('./modules/not-found/not-found.module').then(m => m.NotFoundModule)},
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true})],
    exports: [RouterModule],
})

export class AppRoutingModule {
}
