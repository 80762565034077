import {BaseModel} from './base/base.model';

export abstract class AuditedModel extends BaseModel {
    public createdBy: string;

    public creationDate: Date;

    public modificationDate: Date;

    public modifiedBy: string;

    public constructor() {
        super();
    }
}
