import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {animate, style, transition, trigger} from '@angular/animations';

import {AuthService} from '@service/auth.service';
import {environment} from '@environments/environment';
import {HomeRoutingNames} from '@modules/home/home-routing.names';
import {AdministrationRoutingNames} from '@modules/administration/administration-routing.names';
import {NotificationsRoutingNames} from '@modules/notifications/notifications-routing.names';
import {UserNotificationService} from '@service/user-notification.service';
import {UserNotificationFilter} from '@filter/user-notification.filter';
import {UserNotificationModel} from '@model/user-notification.model';
import {ProfileRoutingNames} from '@modules/profile/profile-routing.names';
import {RoleEnum} from '@model/enums/role.enum';
import {UserService} from '@service/user.service';
import {UserModel} from '@model/user.model';
import {MyUrbisRoutingNames} from '@modules/my-urbis/my-urbis-routing.names';
import {UrbisService} from '@service/urbis.service';
import {ChallengesRoutingNames} from '@modules/challenges/challenges-routing.names';
import {PrizesRoutingNames} from '@modules/prizes/prizes-routing.names';
import {AuctionsRoutingNames} from '@modules/auctions/auctions-routing.names';

export interface Menu {
    label: string;
    url: string;
    icon: string;
    role: any[];
}

@Component({
    selector: 'aud-app-header',
    templateUrl: './app-header.component.html',
    styleUrls: ['./app-header.component.scss'],
    animations: [
        trigger('inOutAnimation',
            [
                transition(':enter',
                    [
                        style({opacity: 0}),
                        animate('.15s ease-in-out',
                            style({opacity: 1}))
                    ]
                ),
                transition(':leave',
                    [
                        style({opacity: 1}),
                        animate('.15s ease-in-out',
                            style({opacity: 0}))
                    ]
                )
            ]
        )
    ]
})
export class AppHeaderComponent implements OnInit {

    public readonly subMenuItems: Menu[] = [
        {
            label: 'COMPONENTS.APP_HEADER.SUB_MENU.HOMEPAGE',
            url: HomeRoutingNames.ROUTE_HOME,
            icon: 'assets/img/icons/home-icon.svg',
            role: [RoleEnum.ADMIN.toString(), RoleEnum.FINAL_USER.toString()]
        },
        {
            label: 'COMPONENTS.APP_HEADER.SUB_MENU.URBIS',
            url: MyUrbisRoutingNames.ROUTE_URBIS,
            icon: 'assets/img/icons/urbis-icon.svg',
            role: [RoleEnum.FINAL_USER.toString()]
        },
        {
            label: 'COMPONENTS.APP_HEADER.SUB_MENU.CHALLENGE',
            url: ChallengesRoutingNames.ROUTE_CHALLENGE,
            icon: 'assets/img/icons/home-icon.svg',
            role: [RoleEnum.FINAL_USER.toString()]
        },
        {
            label: 'COMPONENTS.APP_HEADER.SUB_MENU.PRIZE',
            url: PrizesRoutingNames.ROUTE_PRIZE,
            icon: 'assets/img/icons/home-icon.svg',
            role: []
        },
        {
            label: 'COMPONENTS.APP_HEADER.SUB_MENU.MONITORING',
            url: AdministrationRoutingNames.ROUTE_ADMINISTRATION_MONITORING_MANAGEMENT,
            icon: 'assets/img/icons/home-icon.svg',
            role: [RoleEnum.FINAL_USER.toString()]
        },
        {
            label: 'COMPONENTS.APP_HEADER.SUB_MENU.AUCTIONS',
            url: AuctionsRoutingNames.ROUTE_AUCTIONS,
            icon: 'assets/img/icons/home-icon.svg',
            role: [RoleEnum.FINAL_USER.toString()]
        },
        {
            label: 'COMPONENTS.APP_HEADER.SUB_MENU.ACTIVES_MANAGEMENT',
            url: '#',
            icon: 'assets/img/icons/home-icon.svg',
            role: []
        },
        {
            label: 'COMPONENTS.APP_HEADER.SUB_MENU.HOUSEHOLD_MANAGEMENT',
            url: '#',
            icon: 'assets/img/icons/home-icon.svg',
            role: []
        },
        {
            label: 'COMPONENTS.APP_HEADER.SUB_MENU.USER_MANAGEMENT',
            url: AdministrationRoutingNames.ROUTE_ADMINISTRATION_USER_MANAGEMENT,
            icon: 'assets/img/icons/home-icon.svg',
            role: [RoleEnum.ADMIN.toString()]
        },
        {
            label: 'COMPONENTS.APP_HEADER.SUB_MENU.ADDRESS_MANAGEMENT',
            url: AdministrationRoutingNames.ROUTE_ADMINISTRATION_ADDRESS_MANAGEMENT,
            icon: 'assets/img/icons/home-icon.svg',
            role: [RoleEnum.ADMIN.toString()]
        },
        {
            label: 'COMPONENTS.APP_HEADER.SUB_MENU.ACTIVES_MANAGEMENT',
            url: AdministrationRoutingNames.ROUTE_ADMINISTRATION_ASSETS_MANAGEMENT,
            icon: 'assets/img/icons/home-icon.svg',
            role: [RoleEnum.ADMIN.toString()]
        },
        {
            label: 'COMPONENTS.APP_HEADER.SUB_MENU.TELE_MANAGEMENT',
            url: '#',
            icon: 'assets/img/icons/home-icon.svg',
            role: []
        },
        {
            label: 'COMPONENTS.APP_HEADER.SUB_MENU.DASHBOARDS',
            url: '#',
            icon: 'assets/img/icons/home-icon.svg',
            role: []
        },
        {
            label: 'COMPONENTS.APP_HEADER.SUB_MENU.NOTIFICATIONS',
            url: NotificationsRoutingNames.ROUTE_NOTIFICATIONS,
            icon: 'assets/img/icons/home-icon.svg',
            role: [RoleEnum.ADMIN.toString()]
        },
        {
            label: 'COMPONENTS.APP_HEADER.SUB_MENU.DOCUMENTATION',
            url: '#',
            icon: 'assets/img/icons/home-icon.svg',
            role: []
        }
    ];

    public displayMaximizable: boolean;

    public notificationsRoutingNames = NotificationsRoutingNames;
    public profileRoutingNames = ProfileRoutingNames;
    public notificationData: UserNotificationModel[] = [];
    public notificationFilter: UserNotificationFilter = new UserNotificationFilter();

    public hasNotifications: boolean = false;

    public showAdminMenu: boolean = false;

    public activePage: string = '';
    public textNotification: string = '';
    public dateNotification: string = '';

    public userToken: string;

    public urbisValue: string;

    public urlBE: string;

    public menus: Menu[] = [];

    private readonly urlTl: object = {};

    public userModel: UserModel;

    public constructor(
        private authService: AuthService,
        private notificationService: UserNotificationService,
        private userService: UserService,
        private translate: TranslateService,
        private location: Location,
        private router: Router,
        private urbisService: UrbisService
    ) {
        this.urlTl[HomeRoutingNames.ROUTE_HOME] = this.translate.instant('COMPONENTS.APP_HEADER.SUB_MENU.HOMEPAGE');
        this.urlTl[AdministrationRoutingNames.ROUTE_ADMINISTRATION_USER_MANAGEMENT] = this.translate.instant('COMPONENTS.APP_HEADER.SUB_MENU.USER_MANAGEMENT');
        this.urlTl[AdministrationRoutingNames.ROUTE_ADMINISTRATION_CREATE_USER] = this.translate.instant('COMPONENTS.APP_HEADER.SUB_MENU.USER_MANAGEMENT');
        this.urlTl[NotificationsRoutingNames.ROUTE_NOTIFICATIONS] = this.translate.instant('COMPONENTS.APP_HEADER.SUB_MENU.NOTIFICATIONS');

        this.router.events.subscribe(() => {
            if (this.location.path()) {
                this.activePage = this.urlTl[location.path()];
            }
        });
        this.userToken = this.authService.getRawToken();
        this.urlBE = environment.apiUrl;
    }

    public ngOnInit() {
        this.userModel = this.userService.currentUser;
        this.notificationFilter.user = this.userModel.id;
        this.notificationService.search(this.notificationFilter).subscribe(
            (res: UserNotificationModel[]) => {
                res.filter(notification => {
                    if (!notification.readDate) {
                        this.notificationData.push(notification);
                        this.hasNotifications = true;
                    }
                });
            }
        );


        this.pushMenuitem();
        if (this.userModel.profile.profileRole === 'FINAL_USER') {
            this.search();
        }
    }

    public logout() {
        this.authService.logout();
    }

    public toggleSubMenu() {
        this.showAdminMenu = !this.showAdminMenu;
    }

    public closeSubMenu() {
        this.showAdminMenu = false;
    }

    public setActivePage(page: string) {
        this.activePage = this.translate.instant(page);
    }

    private pushMenuitem() {
        this.subMenuItems.forEach(menu => {
            if (menu.role.includes(this.userModel.profile.profileRole)) {
                this.menus.push(menu);
            }
        });
    }

    public search(): void {
        this.urbisService.readWallet(this.userModel.id).subscribe(entity => {
            this.urbisValue = entity?.result[0].payload.content.amount;
        });
    }

    showNotification(notification) {
        this.textNotification = notification.notification.message;
        this.dateNotification = notification.notification.creationDate;
        this.displayMaximizable = true;
        this.notificationService.readUserNotification(notification.id).subscribe(
            res => console.log(res)
        );
    }
}
