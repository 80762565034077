<nav class="aud-header d-flex justify-content-between aud-menu-shadow">
    <div class="d-flex flex-direction-row align-items-center">
        <div class="logo">
            <img src="assets/img/logo/logo-white.svg" alt="Audacity">
        </div>
        <h5 class="mb-0 aud-ml-50 page-title">{{ activePage }}</h5>
        <!-- <ul class="aud-menu">
            <li *ngFor="let menuItem of menuItems">
                <a [href]="menuItem.url">
                    <img *ngIf="menuItem.icon" [src]="menuItem.icon">
                    {{menuItem.label | translate}}
                </a>
            </li>
        </ul> -->
    </div>

    <div class="d-flex align-items-center">
        <div class="d-flex align-items-center" *ngIf="this.userModel.profile.profileRole === 'FINAL_USER'">
<!--            <img src="assets/img/block.png" class="aud-pr-10"/>-->
            <img src="assets/img/icons/urbis-green-icon.svg"
                 class="aud-pr-10"/>
            <h4 class="aud-pr-10"
                style="color: #fff; padding-top: 5px; padding-right: 35px !important">{{urbisValue}}  </h4>
        </div>
        <button class="aud-btn stripped aud-mr-15" (click)="notifications.toggle($event)">
            <img *ngIf="!hasNotifications" src="assets/img/icons/bell-icon.svg" alt="notificações">
            <img *ngIf="hasNotifications" src="assets/img/icons/bell-notification-icon.svg" alt="notificações">
        </button>
        <button class="aud-btn stripped aud-mr-15 d-flex align-items-center" (click)="user.toggle($event)">
            <h6 class="aud-pr-10" style="color: #fff; padding-top: 5px">{{this.userModel.nameFull}}  </h6>
            <img class="aud-mr-10 user-picture" src="assets/img/icons/account-icon.svg" alt="Utilizador">
            <img src="assets/img/icons/arrow-icon.svg">
        </button>
    </div>
</nav>
<nav class="aud-sub-menu">
    <ul>
        <li *ngFor="let subMenuItem of menus">
            <a [routerLink]="subMenuItem.url" [routerLinkActive]="['active']"
               (click)="setActivePage(subMenuItem.label)">
                <img *ngIf="subMenuItem.icon" [src]="subMenuItem.icon"
                     class="aud-pr-10"/> {{ subMenuItem.label | translate }}
            </a>
        </li>
<!--        <li *ngIf="this.userModel.profile.profileRole === 'FINAL_USER'">-->
        <li>
            <a href="{{urlBE}}oauth/start/{{userToken}}">
                <img  src="assets/img/icons/devices-icon.svg"
                      class="aud-pr-10"/>
                Consumos
            </a>
        </li>
    </ul>
</nav>
<p-overlayPanel #notifications [appendTo]="'body'">
    <ng-template pTemplate>
        <div class="aud-popover-menu">
            <p class="aud-popover-title notif" *ngFor="let notification of notificationData" (click)="showNotification(notification)">
            <span>{{ notification.notification.message }}</span>
            </p>
        </div>
    </ng-template>
</p-overlayPanel>

<p-overlayPanel #user [appendTo]="'body'">
    <ng-template pTemplate>
        <div class="aud-popover-menu">
            <ul>
                <li>
                    <a routerLink="#" [routerLink]="profileRoutingNames.ROUTE_PROFILE">Perfil utilizador</a>
                </li>
            </ul>
            <p class="aud-popover-title">Ações</p>
            <ul>
                <li>
                    <a routerLink="#" (click)="logout()">Logout</a>
                </li>
            </ul>

        </div>
    </ng-template>
</p-overlayPanel>
<p-dialog [(visible)]="displayMaximizable" [modal]="true"
          [style]="{background: 'white'}"
          [maximizable]="true"
          [draggable]="false">
    <div class="container-fluid aud-content" style="padding-top: 0px;">
        <div class="row justify-content-center">
            <div class="col-12 d-flex justify-content-between">
                <div class="align-items-center">
                    <h2>Notificação</h2>
                    <div class="aud-underline aud-mb-30"></div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="aud-card">
                    <div class="w-100 aud-mr-25">
                        <p class="aud-popover-title">{{textNotification}}</p>
                        <br>
                        <br>
                        <p class="aud-popover-title">{{dateNotification | date: 'dd/MM/yyyy'}}</p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</p-dialog>
