import {AuditedModel} from './audited.model';
import {UserModel} from '@model/user.model';
import {ProfileModel} from '@model/profile.model';

export class NotificationModel extends AuditedModel {
    public createdByUser: UserModel;
    public message: string;
    public profile: ProfileModel;

    public constructor() {
        super();
    }
}
