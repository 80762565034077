import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CrudService, EnumInfo, SubTypeInfo} from './crud.service';
import {GeoDistrictModel} from '@model/geodistrict.model';
import {GeoDistrictFilter} from '@filter/geodistrict.filter';

@Injectable()
export class GeoDistrictService extends CrudService<GeoDistrictModel, GeoDistrictFilter> {
    protected entityClass = GeoDistrictModel;

    protected filterClass = GeoDistrictFilter;

    protected entityPath = '/geodistrict';

    public constructor(protected httpClient: HttpClient) {
        super(httpClient);
    }

    protected getEnums(): EnumInfo[] {
        return [];
    }

    protected getSubTypes(): SubTypeInfo[] {
        return [];
    }
}
