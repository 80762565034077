import {AuditedModel} from './audited.model';
import {Deserializable} from './deserializable.model';
import {AuctionModel} from '@model/auction.model';

export class OffersModel extends AuditedModel implements Deserializable {

    public id: string | undefined;
    public idUser: string | undefined;
    public value: any | undefined;
    public startDate: any | undefined;
    public endDate: any | undefined;
    public status: string | undefined;
    public product: string | undefined;
    public amount: number | undefined;
    public auction: AuctionModel | undefined;


    public visible: boolean | undefined;

    public constructor() {
        super();
    }

    public deserialize(input: any): any{
        Object.assign(this, input);
        return this;
    }
}
