import {Component} from '@angular/core';
import {AuthService} from '@service/auth.service';

@Component({
    selector: 'aud-app-layout',
    templateUrl: './app-layout.component.html',
    styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent {

    public constructor(
        private authService: AuthService,
    ) { }

    public logout() {
        this.authService.logout();
        window.location.reload();
    }
}
