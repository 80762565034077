import {ErrorHandler, Injectable, Injector, NgZone} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {AuthService} from '@service/auth.service';
import {MessageService} from 'primeng/api';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    public constructor(private injector: Injector, private authService: AuthService) {
    }

    public handleError(error) {
        console.log(error);
        if (error instanceof HttpErrorResponse && error.status === 401) {
            this.authService.tokenUnauthorized();
            window.location.href = '/';
            return;
        }
        // else if (error instanceof TypeError) {
        //     window.location.href = '/';
        //     return;
        // }
        else if (error.error != null && error.error.errorCode === 'UNAUTHORIZED') {
            this.errorMessage('Erro', 'Não tem permissão para efectuar a ação selecionada.');
        }
        else if (error.message.startsWith('ExpressionChangedAfterItHasBeenCheckedError')) {
            // ignore these for now
            return;
        }
        else {
            this.errorMessage('Ocorreu um erro!', 'Por favor contacte o suporte');
        }
    }

    private errorMessage(summaryMessage: string, detailMessage: string) {
        this.message('error', summaryMessage, detailMessage);
    }

    private message(errorSeverity: string, summaryMessage: string, detailMessage: string) {
        const messageService = this.injector.get(MessageService);
        this.injector.get(NgZone).run(() => {
                messageService.add({severity: errorSeverity, summary: summaryMessage, detail: detailMessage});
            }
        );
    }
}
