import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CrudService, EnumInfo, SubTypeInfo} from './crud.service';
import {Observable} from 'rxjs';
import {environment} from '@environments/environment';
import {catchError, map} from 'rxjs/operators';
import {PrizeFilter} from '@filter/prize.filter';
import {PrizeModel} from '@model/prize.model';

@Injectable()
export class PrizeService extends CrudService<PrizeModel, PrizeFilter> {
    protected entityClass = PrizeModel;

    protected filterClass = PrizeFilter;

    protected entityPath = 'prize';

    public cache = {};

    public constructor(protected httpClient: HttpClient) {
        super(httpClient);
    }

    protected getEnums(): EnumInfo[] {
        return [];
    }

    protected getSubTypes(): SubTypeInfo[] {
        return [];
    }

    public listAll(): Observable<any> {
        return this.httpClient.get<any>(environment.apiBackOfficeUrl + `${this.entityPath}`).pipe(
            map((data: any) => data.map(element => element)),
            catchError(error => this.handleExceptions(error)),
        );
    }

    findAll(): Observable<any> {
        return this.httpClient.get(environment.apiBackOfficeUrl + `${this.entityPath}`);
    }

    public get(entityId: string): Observable<PrizeModel> {
        return this.httpClient.get<PrizeModel>(environment.apiBackOfficeUrl + `${this.entityPath}/${entityId}`).pipe(
            map((data: PrizeModel) => this.mapEntity(data)),
            catchError(error => this.handleExceptions(error)),
        );
    }
}
