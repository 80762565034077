import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {OverlayPanelModule} from 'primeng/overlaypanel';

import {AppLayoutComponent} from './app-layout/app-layout.component';
import {AppHeaderComponent} from './app-header/app-header.component';
import {AppLoaderComponent} from './app-loader/app-loader.component';
import {ToastModule} from 'primeng/toast';
import {ButtonModule} from 'primeng/button';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DialogModule} from 'primeng/dialog';

@NgModule({
    declarations: [
        AppLayoutComponent,
        AppHeaderComponent,
        AppLoaderComponent,
    ],
    imports: [
        ToastModule,
        RouterModule,
        CommonModule,
        BrowserAnimationsModule,
        ButtonModule,
        TranslateModule,
        ConfirmDialogModule,
        OverlayPanelModule,
        DialogModule,
    ],
    exports: [
        AppLayoutComponent,
        AppHeaderComponent,
        AppLoaderComponent,
    ]
})

export class LayoutModule { }
