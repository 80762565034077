import {Observable} from 'rxjs';

import {BaseModel} from '@model/base/base.model';
import {ResponseError} from '@model/base/response-error.model';
import {ResponseBase} from '@model/base/response.model';

import {ExceptionErrorCodesEnum} from '@model/enums/utility-enums/exception-error-codes.enum';

export abstract class BaseService<M extends BaseModel> {

    protected constructor() { }

    protected handleExceptions(err: any): Observable<any> {
        const error = err.error;
        return new Observable((observer: any) => {
            if (error && ExceptionErrorCodesEnum.VALIDATION.is(error.errorCode)) {
                observer.error(this.buildResponseError(error.additional));
            } else if (error && ExceptionErrorCodesEnum.INTERNAL.is(error.errorCode)) {
                observer.error(this.buildResponseError());
            } else if (error && error.errorCode) {
                observer.error(this.buildOneResponseError(error.errorCode));
            } else {
                observer.error(this.buildResponseError());
            }

            observer.complete();
        });
    }

    protected buildResponseError(errors?: any): ResponseBase<M> {
        const response = new ResponseBase<M>();

        response.success = false;
        response.count = 0;

        if (errors) {
            const keys = Object.keys(errors);
            const respErrors: ResponseError[] = [];

            for (const key of keys) {
                const error = errors[key];
                respErrors.push(new ResponseError(key, error));
            }

            response.errors = respErrors;
        } else {
            response.errors = [];
        }

        return response;
    }

    protected buildOneResponseError(error: any): ResponseBase<M> {
        const response = new ResponseBase<M>();

        response.success = false;
        response.count = 0;

        response.errors = [new ResponseError(null, error)];

        return response;
    }
}
