import {AuditedModel} from './audited.model';
import {ContactModel} from './contact.model';
import {HouseholdMembersModel} from './household-members.model';
import {ProfileModel} from './profile.model';
import {Deserializable} from './deserializable.model';

export class UserModel extends AuditedModel implements Deserializable {
    public contact: ContactModel;
    public addresses: any [] = [];
    public householdMembers: HouseholdMembersModel;
    public profile: ProfileModel;
    public nameFull: string;
    public nameShort: string;
    public fiscalNumber: number;
    public newPassword: string;
    public customerId: string;
    public newPasswordConfirmation: string;
    public status: any;

    public constructor() {
        super();
    }

    public deserialize(input: any){
        Object.assign(this, input);
        this.contact = new ContactModel(input.emailPrimary, null, input.mobilePhone, null).deserialize(input.contact);
        return this;
    }
}
