import {BaseModel} from './base/base.model';
import {Deserializable} from './deserializable.model';

export class GeoCountyModel extends BaseModel implements Deserializable {
    public code: string;
    public description: string;
    public geoDistrictId: string;
    public geoDistrictDesc: string;

    public constructor() {
        super();
    }

    public deserialize(input: any){
        Object.assign(this, input);
        return this;
    }
}
