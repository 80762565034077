import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {CrudService, EnumInfo, SubTypeInfo} from './crud.service';
import {UserModel} from '@model/user.model';
import {UserFilter} from '@filter/user.filter';
import {StatusEnum} from '@model/enums/status.enum';
import {ProfileService} from '@service/profile.service';
import {AddressService} from '@service/address.service';
import {environment} from '@environments/environment';
import {DeviceModel} from '@model/device.model';

@Injectable()
export class UserService extends CrudService<UserModel, UserFilter> {
    protected entityClass = UserModel;

    protected filterClass = UserFilter;

    protected entityPath = '/user';

    private storage: Storage = localStorage;

    private currentUserData: UserModel;

    public constructor(protected httpClient: HttpClient, protected profileService: ProfileService, protected addressService: AddressService) {
        super(httpClient);
    }

    protected getEnums(): EnumInfo[] {
        return [
            new EnumInfo('status', StatusEnum)
        ];
    }

    protected getSubTypes(): SubTypeInfo[] {
        return [
            new SubTypeInfo('profile', this.profileService),
            new SubTypeInfo('addresses', this.addressService),
        ];
    }

    public loggedUser(): Observable<UserModel> {
        return this.httpClient.get<UserModel>(environment.apiUrl + `${this.entityPath}/loggedUser`).pipe(
            map((data: UserModel) => data),
            catchError(error => this.handleExceptions(error))
        );
    }

    public setUser(user: UserModel) {
        this.storage.setItem('aud_userData', JSON.stringify(user));
    }

    public get currentUser(): UserModel {
        if (!this.currentUserData) {
            this.currentUserData = JSON.parse(this.storage.getItem('aud_userData'));
        }

        return this.currentUserData;
    }


    public allDevices(filter: any): Observable<any> {
        return this.httpClient.post<any>(environment.apiUrl + `${this.entityPath}/devices`, filter).pipe(
            map((data: any) => data),
            catchError(error => this.handleExceptions(error))
        );
    }


    public devicesByCustomer(filter: any): Observable<DeviceModel> {
        return this.httpClient.post<DeviceModel>(environment.apiUrl + `${this.entityPath}/devices-customer`, filter).pipe(
            map((data: any) => data),
            catchError(error => this.handleExceptions(error)),
        );
    }

    public toAssign(customerId: string, deviceId: string): Observable<any> {
        return this.httpClient.post<any>(environment.apiUrl + `${this.entityPath}/assign/${customerId}/device/${deviceId}`, {}).pipe(
            map((data: any) => this.mapEntity(data)),
            catchError(error => this.handleExceptions(error)),
        );
    }

    public telemetry(customerId: string): Observable<any> {
        return this.httpClient.get<any>(environment.apiUrl + `${this.entityPath}/telemetry/${customerId}`).pipe(
            map((data: any) => data),
            catchError(error => this.handleExceptions(error))
        );
    }

    public toUnAssign(customerId: string, deviceId: string): Observable<any> {
        return this.httpClient.post<any>(environment.apiUrl + `${this.entityPath}/unassign/${customerId}/device/${deviceId}`, {}).pipe(
            map((data: any) => this.mapEntity(data)),
            catchError(error => this.handleExceptions(error)),
        );
    }

}
