import {AuditedModel} from './audited.model';
import {Deserializable} from './deserializable.model';

export class ChallengeModel extends AuditedModel implements Deserializable {

    public id: string | undefined;
    public title: string | undefined;
    public description: string | undefined;
    public image: any | undefined;
    public recurrent: boolean | undefined;
    public recurrence: string | undefined;
    public startDate: Date | undefined;
    public endDate: Date | undefined;
    public status: string | undefined;
    public visible: boolean | undefined;

    public constructor() {
        super();
    }

    public deserialize(input: any): any{
        Object.assign(this, input);
        return this;
    }
}
