import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';

import {ReflectionUtils} from '@util/ReflectionUtils';
import {Enumerable} from '@util/Enum';
import {BaseModel} from '@model/base/base.model';

@Injectable()
export class EnumInterceptor implements HttpInterceptor {

    public constructor() { }

    public fixEnum(newBody: any) {
        if (newBody == null) {
            return null;
        } else {
            if (Array.isArray(newBody)) {
                newBody.forEach(el => this.fixEnum(el));
            } else {
                ReflectionUtils.getAllProperties(newBody)
                    .filter((prop) => newBody[prop] instanceof Enumerable)
                    .forEach((prop) => newBody[prop] = (newBody[prop] as Enumerable).enumName);

                ReflectionUtils.getAllProperties(newBody)
                    .filter((prop) => Array.isArray(newBody[prop]) || newBody[prop] instanceof BaseModel)
                    .forEach((prop) => newBody[prop] = this.fixEnum(newBody[prop]));
            }

            return newBody;
        }
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let newBody: any = req.body;

        newBody = this.fixEnum(newBody);

        req = req.clone({
            body: newBody,
        });

        return next.handle(req).pipe(
            finalize(() => { })
        );
    }
}
