import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CrudService, EnumInfo, SubTypeInfo} from './crud.service';
import {Observable} from 'rxjs';
import {environment} from '@environments/environment';
import {catchError, map} from 'rxjs/operators';
import {AuctionModel} from '@model/auction.model';
import {AuctionFilter} from '@filter/auction.filter';

@Injectable()
export class AuctionService extends CrudService<AuctionModel, AuctionFilter> {
    protected entityClass = AuctionModel;

    protected filterClass = AuctionFilter;

    protected entityPath = 'auction';

    public cache = {};

    public constructor(protected httpClient: HttpClient) {
        super(httpClient);
    }

    protected getEnums(): EnumInfo[] {
        return [];
    }

    protected getSubTypes(): SubTypeInfo[] {
        return [];
    }

    public listAll(): Observable<AuctionModel[]> {
        return this.httpClient.get<AuctionModel[]>(environment.apiBackOfficeUrl + `${this.entityPath}`).pipe(
            map((data: AuctionModel[]) => data.map(element => element)),
            catchError(error => this.handleExceptions(error)),
        );
    }

    findAll(): Observable<any> {
        return this.httpClient.get(environment.apiBackOfficeUrl + `${this.entityPath}`);
    }

    public get(entityId: string): Observable<AuctionModel> {
        return this.httpClient.get<AuctionModel>(environment.apiBackOfficeUrl + `${this.entityPath}/${entityId}`).pipe(
            map((data: AuctionModel) => this.mapEntity(data)),
            catchError(error => this.handleExceptions(error)),
        );
    }
}
