import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CrudService, EnumInfo, SubTypeInfo} from './crud.service';
import {GeoCountyFilter} from '@filter/geocounty.filter';
import {GeoCountryModel} from '@model/geocountry.model';

@Injectable()
export class GeocountryService extends CrudService<GeoCountryModel, GeoCountyFilter> {
    protected entityClass = GeoCountryModel;

    protected filterClass = GeoCountyFilter;

    protected entityPath = '/geocountry';

    public constructor(protected httpClient: HttpClient) {
        super(httpClient);
    }

    protected getEnums(): EnumInfo[] {
        return [];
    }

    protected getSubTypes(): SubTypeInfo[] {
        return [];
    }
}
