import {
    Component,
    ContentChild,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators
} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

import {AudInputExtraButtonDirective} from './aud-input.directives';

@Component({
  selector: 'aud-input',
  templateUrl: './aud-input.component.html',
  styleUrls: ['./aud-input.component.scss'],
  providers: [
      { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AudInputComponent), multi: true },
      { provide: NG_VALIDATORS, useExisting: forwardRef(() => AudInputComponent), multi: true },
  ],
})
export class AudInputComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {
    public constructor(
        public translate: TranslateService,
    ) { }

    @Input() public name: string;
    @Input() public label: string;
    @Input() public placeholder: string = '';
    @Input() public labelTooltip: string;
    @Input() public fieldType: 'date' | 'text' | 'number' | 'password' | 'email' = 'text';
    @Input() public readOnly: boolean = false;
    @Input() public dropSpecialCharacters: boolean = false;
    @Input() public showErrors: boolean = true;
    @Input() public required: boolean = false;
    @Input() public slimVersion: boolean = false;
    @Input() public customLabelClass: string;
    @Input() public customClass: string;
    @Input() public formGroupClass: string = '';
    @Input() public inputMask: string;
    @Input() public inputMaskPattern: string;
    @Input() public prefix: string;

    @Input() public validatorMinNumber: number;
    @Input() public validatorMaxNumber: number;
    @Input() public validatorMinLength: number;
    @Input() public validatorMaxLength: number;
    @Output() public changeEvent = new EventEmitter<any>();

    @ContentChild(AudInputExtraButtonDirective, { read: TemplateRef }) public audInputExtraButton: TemplateRef<HTMLElement>;

    // tslint:disable-next-line:variable-name
    private _value: any;

    public formGroup: FormGroup;
    public propagateChange: any = () => {};
    public propagateTouch: any = () => {};
    public propagateValidatorChange: any = () => {};

    public ngOnInit() {
        this.initForm();
    }

    public onChange($event: any) {
        this._value = this.input.value;
        this.propagateChange(this._value);
        this.changeEvent.emit(this._value);
    }

    /////////////////////////////////////////////
    // FORM
    /////////////////////////////////////////////
    private initForm() {
        this.formGroup = new FormGroup({
            input: new FormControl({ value: this._value, disabled: this.readOnly }),
        });

        this.applyValidators();
    }

    private applyValidators() {
        const inputValidators = [];

        if (this.fieldType === 'email') {
            inputValidators.push(Validators.email);
        }

        if (this.fieldType === 'number') {
            if (this.validatorMinNumber != null) {
                inputValidators.push(Validators.min(this.validatorMinNumber));
            }

            if (this.validatorMaxNumber != null) {
                inputValidators.push(Validators.max(this.validatorMaxNumber));
            }
        }

        if (this.validatorMinLength != null) {
            inputValidators.push(Validators.minLength(this.validatorMinLength));
        }

        if (this.validatorMaxLength != null) {
            inputValidators.push(Validators.maxLength(this.validatorMaxLength));
        }

        // if (this.required != null) {
        //     debugger;
        //     inputValidators.push(Validators.required);
        // }


        this.input.setValidators(inputValidators);
    }

    public get input(): FormControl {
        if (this.formGroup) {
            return this.formGroup.get('input') as FormControl;
        }
    }

    /////////////////////////////////////////////
    // Interfaces implementation
    /////////////////////////////////////////////
    public writeValue(value: any) {
        this._value = value;
        this.input.setValue(this._value);
    }

    public registerOnChange(fn: () => void) {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled == null) {
            isDisabled = false;
        }

        this.readOnly = isDisabled;

        if (this.input) {
            isDisabled ? this.input.disable() : this.input.enable();
        }
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.propagateValidatorChange = fn;
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        return this.formGroup.invalid ? this.input.errors : null;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        for (const propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                if (propName === 'readOnly') {
                    const change = changes[propName];
                    const previousValue = change.previousValue;
                    const currentValue = change.currentValue;

                    if (previousValue !== currentValue) {
                        this.setDisabledState(currentValue);
                    }
                }

                if (propName === 'required') {
                    const change = changes[propName];
                    const currentValue = change.currentValue;

                    if (this.input) {
                        if (currentValue) {
                            this.input.setValidators(Validators.required);
                        } else {
                            this.input.clearValidators();
                        }
                    }
                }
            }
        }
    }
}
