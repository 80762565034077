import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CrudService, EnumInfo, SubTypeInfo} from './crud.service';
import {NotificationModel} from '@model/notification.model';
import {NotificationFilter} from '@filter/notification.filter';
import {UserService} from '@service/user.service';

@Injectable()
export class NotificationService extends CrudService<NotificationModel, NotificationFilter> {
    protected entityClass = NotificationModel;

    protected filterClass = NotificationFilter;

    protected entityPath = '/notification';

    public constructor(protected httpClient: HttpClient, protected userService: UserService) {
        super(httpClient);
    }

    protected getEnums(): EnumInfo[] {
        return [];
    }

    protected getSubTypes(): SubTypeInfo[] {
        return [
            new SubTypeInfo('createdByUser', this.userService)
        ];
    }
}
