import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {NgSelectModule} from '@ng-select/ng-select';

import {AudInputComponent} from './aud-input/aud-input.component';
import {AudCheckboxComponent} from './aud-checkbox/aud-checkbox.component';
import {AudUserCardComponent} from './aud-user-card/aud-user-card.component';
import {AudTableComponent} from './aud-table/aud-table.component';
import {AudComboboxComponent} from './aud-combobox/aud-combobox.component';

import {AudInputExtraButtonDirective} from './aud-input/aud-input.directives';
import {AudTableFiltersDirective} from './aud-table/aud-table.directives';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AudUrbisComponent} from './aud-urbis/aud-urbis.component';
import {AudUrbisFiltersDirective} from './aud-urbis/aud-urbis.directives';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';

@NgModule({
    declarations: [
        AudInputComponent,
        AudCheckboxComponent,
        AudUserCardComponent,
        AudTableComponent,
        AudComboboxComponent,
        AudUrbisComponent,
        AudInputExtraButtonDirective,
        AudTableFiltersDirective,
        AudUrbisFiltersDirective
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        MessagesModule,
        MessageModule,
        NgxDatatableModule,
        NgSelectModule,
        NgbModule,
    ],
    exports: [
        AudInputComponent,
        AudCheckboxComponent,
        AudUserCardComponent,
        AudTableComponent,
        AudComboboxComponent,
        AudUrbisComponent,
        AudUrbisFiltersDirective,
        AudInputExtraButtonDirective,
        AudTableFiltersDirective,
    ],
})
export class ComponentModule {
}
