import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ContentChild,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewRef
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators
} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

import {BaseModel} from '@model/base/base.model';
import {BaseFilter} from '@filter/base/base.filter';

import {CrudService} from '@service/crud.service';

import {AudComboboxLabelOptionDisplayDirective, AudComboboxReadOnlyDisplayDirective} from './aud-combobox.directives';

import {environment} from '@environments/environment';

@Component({
    selector: 'aud-combobox',
    templateUrl: './aud-combobox.component.html',
    styleUrls: ['./aud-combobox.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AudComboboxComponent), multi: true },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => AudComboboxComponent), multi: true },
    ],
})
export class AudComboboxComponent <M extends BaseModel, F extends BaseFilter, S extends CrudService<M, F>> implements OnInit, OnChanges, OnDestroy, AfterViewInit, ControlValueAccessor, Validator {
    @Input() public service: S;
    @Input() public initFilter: F;
    @Input() public items: M[] = [];

    @Input() public loadingText: string;
    @Input() public placeholder: string;
    @Input() public notFoundText: string;
    @Input() public appendToBody: boolean = true;

    @Input() public name: string;
    @Input() public label: string;
    @Input() public bindValue: string;
    @Input() public bindLabel: string;
    @Input() public bindLabelIsTranslateKey: boolean = false;
    @Input() public filterSearchField: string;
    @Input() public multiple: boolean = false;
    @Input() public maxSelectedItems: number;

    @Input() public extraFormGroupClass: string = '';
    @Input() public readOnly: boolean = false;
    @Input() public showErrors: boolean = true;
    @Input() public required: boolean = false;
    @Input() public clearable: boolean = true;
    @Input() public searchable: boolean = true;
    @Input() public loadItemsOnInit: boolean = false;
    @Input() public optionInfoTooltip: string;
    @Input() public optionInfoTemplate: TemplateRef<any>;

    @Output() public changeEvent = new EventEmitter<{ value: any, object: any }>();

    @ContentChild(AudComboboxLabelOptionDisplayDirective, {read: TemplateRef}) public labelOptionDisplay: TemplateRef<any>;
    @ContentChild(AudComboboxReadOnlyDisplayDirective, {read: TemplateRef}) public readOnlyDisplay: TemplateRef<any>;

    public loading: boolean = false;
    public formGroup: FormGroup;

    // INTERNAL VARIABLES
    private _filter: F;
    // if the the provided filter, provides an initial search term, we save the initial value to be able to reset the filter without losing this value
    private _defaultSearchTerm: string;
    private _value: any;
    private _currentItemsDirty: boolean = false;
    private _destroyed: boolean;
    public _valueObj: M | M[];
    public _currentItems: M[];

    private txtQueryChanged: Subject<any> = new Subject<any>();

    public propagateChange: any = () => {};
    public propagateTouch: any = () => {};
    public propagateValidatorChange: any = () => {};

    public constructor(
        private translateService: TranslateService,
        private cdRef: ChangeDetectorRef,
    ) {
        this.txtQueryChanged.pipe(
            debounceTime(750),
            distinctUntilChanged(),
        ).subscribe(model => {
            this.onSearch(model);
        });
    }

    // INITIALIZATIONS
    public ngOnInit(): void {
        this._destroyed = false;
        this.initForm();

        if (!this.notFoundText) {
            this.notFoundText = this.translateService.instant('COMPONENTS.AUD_COMBOBOX.NO_RESULTS');
        }

        if (!this.loadingText) {
            this.loadingText = this.translateService.instant('COMPONENTS.AUD_COMBOBOX.LOADING');
        }

        this.initCombo();
    }

    public ngAfterViewInit() {
        this.loadOnInit();
    }

    private initCombo() {
        this._currentItems = this.items?.length ? this.items : this._currentItems;
        this._filter = this.initFilter ? this.initFilter : this._filter;
        this._currentItemsDirty = false;

        if (this.service) {
            const filter = this.getFilter();

            if (filter) {
                if (this.filterSearchField) {
                    this._defaultSearchTerm = filter[this.filterSearchField];
                }
            }
        }
    }

    private loadOnInit() {
        if (this.loadItemsOnInit && !this._value) {
            this.onSearch();
        }
    }

    public onChange(value) {
        if (value) {
            this._value = this.bindValue ? this.inputCombobox.value[this.bindValue] : this.inputCombobox.value;
            this._valueObj = value;
        } else {
            this._value = null;
            this._valueObj = null;
        }

        this.propagateChange(!this.multiple ? this._value : this._valueObj);
        this.changeEvent.emit({value: this._value, object: value});
    }

    public onClear($event: {}) {
        if (this.service && this.filterSearchField) {
            const filter = this.getFilter();

            filter[this.filterSearchField] = undefined;
        }
    }

    public onOpen($event: {}) {
        this.searchItemsInit();
    }

    public onClose($event: {}) {
        if (this.service && this.filterSearchField) {
            const filter = this.getFilter();

            filter[this.filterSearchField] = undefined;
        }
    }

    public onCurrentItemsUpdate() {
        if (this._currentItems && this._currentItems.length === 1 && !this.multiple) {

            const currentItem = this._currentItems[0];
            let sameObject = true;

            if (this._valueObj) {
                if (this.bindValue) {
                    if (this._valueObj[this.bindValue] !== currentItem[this.bindValue]) {
                        sameObject = false;
                    }
                } else if (this._valueObj !== currentItem) {
                    if (typeof this._valueObj === 'object') {
                        for (const key in this._valueObj) {
                            if (typeof this._valueObj[key] === 'object') {
                                if (JSON.stringify(this._valueObj[key]) !== JSON.stringify(currentItem[key])) {
                                    sameObject = false;
                                    break;
                                }
                            } else {
                                if (this._valueObj[key] !== currentItem[key]) {
                                    sameObject = false;
                                    break;
                                }
                            }
                        }
                    } else {
                        sameObject = false;
                    }
                }
            } else {
                sameObject = false;
            }

            if (!sameObject) {
                if (this.multiple) {
                    if (this.bindValue && currentItem[this.bindValue]) {
                        if (this.inputCombobox.value) {
                            this.setValue([...this.inputCombobox.value, currentItem[this.bindValue]]);
                        } else {
                            this.setValue(currentItem[this.bindValue]);
                        }
                    } else {
                        if (this.inputCombobox.value) {
                            this.setValue([...this.inputCombobox.value, currentItem]);
                        } else {
                            this.setValue(currentItem);
                        }
                    }
                } else {
                    if (this.bindValue && currentItem[this.bindValue]) {
                        this.setValue(currentItem[this.bindValue]);
                    } else {
                        this.setValue(currentItem);
                    }
                }
            }
        }
    }

    public searchDebounce($event?: { term: string; items: any[] }) {
        this.txtQueryChanged.next($event);

        if ($event.term === '') {
            this.setValue(null);
        }
    }

    /////////////////////////////////////////////
    // Search
    /////////////////////////////////////////////
    // FIXME this is not an event to perform search, this event occurs after ng-select local-search
    public onSearch($event?: { term: string; items: any[] }): Promise<any> {
        if (this.service && this.filterSearchField) {
            this.resetFilter();
            const filter = this.getFilter();

            if ($event && $event.term) {
                filter[this.filterSearchField] = $event.term;
                this._currentItemsDirty = true;
            } else {
                filter[this.filterSearchField] = undefined;
                this._currentItemsDirty = false;
            }

            return this.searchItemsInternal(filter).then(items => {
                this._currentItems = items;
                this.onCurrentItemsUpdate();
            }).catch(reason => {
                console.log(reason);
                this._currentItems = [];
            });
        } else {
            this._currentItems = this.items;

            if ($event && $event.term) {
                this._currentItems = this._currentItems.filter(item => {
                    this._currentItemsDirty = true;

                    return this.localSearch($event.term, item);
                });

                this.onCurrentItemsUpdate();
            } else {
                this._currentItemsDirty = false;
            }

            return Promise.resolve();
        }
    }

    private localSearch(term: string, item: M): boolean {
        if (term) {
            term = term.toLocaleLowerCase();
            let itemLabelToSearch = this.bindLabel ? item[this.bindLabel] : JSON.stringify(item);

            if (this.bindLabelIsTranslateKey) {
                itemLabelToSearch = this.translateService.instant(itemLabelToSearch);
            }

            return itemLabelToSearch.toLocaleLowerCase().indexOf(term) > -1;
        }

        return false;
    }

    private searchItemsInit(): Promise<void> {
        if (this.service) {
            this.resetFilter();
            return this.searchItemsInternal(this.getFilter()).then(items => {
                this._currentItems = [...items];
                this.onCurrentItemsUpdate();
            }).catch(reason => console.log(reason));
        } else {
            this.onCurrentItemsUpdate();

            return Promise.resolve();
        }
    }

    private searchItemsInternal(filter: F): Promise<M[]> {
        if (this._destroyed || (this.cdRef && (this.cdRef as ViewRef).destroyed)) {
            if (!environment.production) {
                console.error(`
                    Combobox ${this.name}
                     :: If you are seeing this, it means that there is a bug in your code.
                     This component already has been destroyed and you are still trying performing actions on it.`);
            }

            return Promise.resolve([] as M[]);
        }

        if (this.service && !this.readOnly) {
            this.loading = true;
            this.cdRef.detectChanges();

            return this.service.search(filter).toPromise().finally(
                () => {
                    this.loading = false;

                    if (this._destroyed || (this.cdRef && (this.cdRef as ViewRef).destroyed)) {
                        if (!environment.production) {
                            console.error(`Combobox ${this.name}
                                 :: If you are seeing this, it means that there is a bug in your code.
                                  This component already has been destroyed and you are still trying performing actions on it.`);
                        }

                        return;
                    } else {
                        this.cdRef.detectChanges();
                    }
                },
            );
        } else {
            return Promise.resolve([] as M[]);
        }
    }

    private resetFilter() {
        if (this.service) {
            const filter = this.getFilter();

            if (filter) {
                // only resetting the pagination with the defaults of an empty filter
                // we dont touch on other filters to avoid losing any combo default filters
                const emptyFilter = this.service.buildFilterParameters();
                filter.pageSize = emptyFilter.pageSize;
                filter.start = emptyFilter.start;

                // resetting a possible default search term to its original value
                if (this.filterSearchField) {
                    filter[this.filterSearchField] = this._defaultSearchTerm;
                }
            }
        }
    }

    public clear() {
        this.writeValue(null);
        this.onClear(null);
        this.onChange(null);
    }

    public setValue(value) {
        this.writeValue(value);
        this.onChange(value);
    }

    public getFilter() {
        if (this.service && !this._filter) {
            this._filter = this.service.buildFilterParameters();
        }

        return this._filter;
    }

    /////////////////////////////////////////////
    // PAGINATION
    /////////////////////////////////////////////
    public onScrollToEnd() {
        if (this.service) {
            this.fetchMore();
        }
    }

    private fetchMore() {
        if (this.service) {
            const filter = this.getFilter();
            filter.start = this._currentItems.length;

            this.searchItemsInternal(filter).then(items => {
                this._currentItems = [...this._currentItems, ...items];
            }).catch(reason => console.log(reason));
        }
    }

    /////////////////////////////////////////////
    // FORM and VALIDATION
    /////////////////////////////////////////////
    private initForm() {
        const validators = [];

        if (this.required) {
            validators.push(Validators.required);
        }

        this.formGroup = new FormGroup({
            inputCombobox: new FormControl({value: this._value, disabled: this.readOnly}, validators),
        });
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        return this.formGroup.invalid ? this.inputCombobox.errors : null;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.propagateValidatorChange = fn;
    }

    public get inputCombobox() {
        return this.formGroup.get('inputCombobox');
    }

    /////////////////////////////////////////////
    // ControlValueAccessor implementation
    /////////////////////////////////////////////
    public writeValue(value: any) {
        if (value != null && value !== undefined) {
            this._value = value;

            if (this.bindValue) {
                this._valueObj = this._currentItems.find((valueToSearch, index) => {
                    if (valueToSearch[this.bindValue] === value) {
                        return true;
                    }

                    return false;
                });

                if (!this._valueObj) {
                    this._valueObj = {
                        [this.bindValue]: value,
                    } as M;
                }
            } else {
                this._valueObj = value;
            }
        } else {
            this._value = null;
            this._valueObj = null;
        }

        this.inputCombobox.setValue(this._valueObj);
    }

    public registerOnChange(fn: () => void) {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.readOnly = isDisabled;

        if (!this.readOnly) {
            this.initCombo();
        }
    }

    /////////////////////////////////////////////
    // Other interfaces
    /////////////////////////////////////////////
    public ngOnChanges(changes: SimpleChanges): void {
        if (this.formGroup) {
            for (const propName in changes) {
                if (changes.hasOwnProperty(propName)) {
                    const change = changes[propName];
                    const previousValue = change.previousValue;
                    const currentValue = change.currentValue;

                    switch (propName) {
                        case 'required': {
                            if (previousValue !== currentValue) {
                                if (currentValue) {
                                    this.inputCombobox.setValidators(Validators.required);
                                    this.inputCombobox.updateValueAndValidity();
                                } else {
                                    this.inputCombobox.clearValidators();
                                    this.inputCombobox.updateValueAndValidity();
                                }
                            }
                            break;
                        }
                        case 'items': {
                            if (previousValue !== currentValue) {
                                this.initCombo();
                            }
                            break;
                        }
                        case 'readonly': {
                            if (previousValue !== currentValue) {
                                if (!this.readOnly) {
                                    this.initCombo();
                                }
                            }
                            break;
                        }
                        case 'initFilter': {
                            if (previousValue !== currentValue) {
                                if (!this.readOnly) {
                                    this.initCombo();
                                }
                            }
                            break;
                        }
                        default: {
                            break;
                        }
                    }
                }
            }
        }
    }

    public ngOnDestroy(): void {
        this._destroyed = true;
    }
}
