export class ReflectionUtils {
    public static getAllProperties(obj): string[] {
        const allProps = [];
        let curr = obj;

        do {
            const props = Object.getOwnPropertyNames(curr);

            props.forEach((prop) => {
                if (allProps.indexOf(prop) === -1) {
                    allProps.push(prop);
                }
            });
            // tslint:disable-next-line:no-conditional-assignment
        } while (curr = Object.getPrototypeOf(curr));

        return allProps;
    }
}
