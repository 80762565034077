import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CrudService, EnumInfo, SubTypeInfo} from './crud.service';
import {GeoPostalCodeModel} from '@model/geo-postal-code.model';
import {GeoPostalCodeFilter} from '@filter/geo-postal-code.filter';

@Injectable()
export class GeoPostalCodeService extends CrudService<GeoPostalCodeModel, GeoPostalCodeFilter> {
    protected entityClass = GeoPostalCodeModel;

    protected filterClass = GeoPostalCodeFilter;

    protected entityPath = '/geopostalcode';

    public constructor(protected httpClient: HttpClient) {
        super(httpClient);
    }

    protected getEnums(): EnumInfo[] {
        return [];
    }

    protected getSubTypes(): SubTypeInfo[] {
        return [];
    }
}
