import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CrudService, EnumInfo, SubTypeInfo} from './crud.service';
import {GeoPlaceModel} from '@model/geoplace.model';
import {GeoPlaceFilter} from '@filter/geoplace.filter';

@Injectable()
export class GeoPlaceService extends CrudService<GeoPlaceModel, GeoPlaceFilter> {
    protected entityClass = GeoPlaceModel;

    protected filterClass = GeoPlaceFilter;

    protected entityPath = '/geoplace';

    public constructor(protected httpClient: HttpClient) {
        super(httpClient);
    }

    protected getEnums(): EnumInfo[] {
        return [];
    }

    protected getSubTypes(): SubTypeInfo[] {
        return [];
    }
}
