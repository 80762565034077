import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CrudService, EnumInfo, SubTypeInfo} from './crud.service';
import {GroupModel} from '@model/group.model';
import {GroupFilter} from '@filter/group.filter';

@Injectable()
export class GroupService extends CrudService<GroupModel, GroupFilter> {
    protected entityClass = GroupModel;

    protected filterClass = GroupFilter;

    protected entityPath = '/group';

    public constructor(protected httpClient: HttpClient) {
        super(httpClient);
    }

    protected getEnums(): EnumInfo[] {
        return [];
    }

    protected getSubTypes(): SubTypeInfo[] {
        return [];
    }
}
