import {Directive, TemplateRef} from '@angular/core';

@Directive({selector: '[audComboboxLabelOptionDisplay]'})
export class AudComboboxLabelOptionDisplayDirective {
    public constructor(public template: TemplateRef<any>) { }
}

@Directive({selector: '[audComboboxReadOnlyDisplay]'})
export class AudComboboxReadOnlyDisplayDirective {
    public constructor(public template: TemplateRef<any>) { }
}
