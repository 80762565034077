import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CrudService, EnumInfo, SubTypeInfo} from './crud.service';
import {UrbisModel} from '@model/urbis.model';
import {UrbisFilter} from '@filter/urbis.filter';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {environment} from '@environments/environment';

@Injectable()
export class UrbisService extends CrudService<UrbisModel, UrbisFilter> {
    protected entityClass = UrbisModel;

    protected filterClass = UrbisFilter;

    protected entityPath = '/urbis';

    public cache = {};

    public constructor(protected httpClient: HttpClient) {
        super(httpClient);
    }

    protected getEnums(): EnumInfo[] {
        return [];
    }

    protected getSubTypes(): SubTypeInfo[] {
        return [];
    }

    public readWallet(entityId: string, forceLoad: boolean = false): Observable<any> {
         if (this.cache[entityId] && !forceLoad) {
            return of(this.cache[entityId]);
         }
         return this.httpClient.get<any>(environment.apiUrl + `${this.entityPath}/read-wallet/${entityId}`).pipe(
             map((data: UrbisModel) => this.mapEntity(data)),
             tap(resolvedValue => {
                 this.cache[entityId] = resolvedValue;
             }),
             catchError(error => this.handleExceptions(error)),
         );
    }

    public updateWallet(entity: any): Observable<any>{
        return this.httpClient.put<any>(`${environment.apiUrl}${this.entityPath}/update-wallet`, entity).pipe(
            map((data: any) => this.mapEntity(data)),
            catchError(error => this.handleExceptions(error)),
        );
    }
}
