import {Deserializable} from './deserializable.model';

export class ContactModel implements Deserializable {
    public id: string;
    public emailPrimary: string;
    public emailSecondary: string;
    public mobilePhone: string;
    public homePhone: string;

    public constructor(
        emailPrimary: string,
        emailSecondary: string,
        mobilePhone: string,
        homePhone: string,
    ) {
        this.emailPrimary = emailPrimary;
        this.emailSecondary = emailSecondary;
        this.mobilePhone = mobilePhone;
        this.homePhone = homePhone;
    }

    public deserialize(input: any){
        Object.assign(this, input);
        return this;
    }
}
